import React, { useEffect, useRef } from "react";
import { componentKey as PatientDashboardComponentKey, setHipNotifyData } from "../PatientDashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import { addNotifications } from "../../../../components/common-components/toaster/ToasterSlice";
import { TOASTER_VARIANT } from "../../../../components/common-components/toaster/MetToaster";
import "./SandClock.css";
import { hipNotify } from "../PatientDashboardSaga";
import Button from "../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";
import { SandClock } from "./GenerateTokenPopup";
const socketURL = process.env.REACT_APP_API_URL.replace(/\/api$/, '');

function HipNotifyPopup ({close, onConfirm}) {
    const { careContextLinkingData, hipNotifyData  } = useSelector((state) => state[PatientDashboardComponentKey]);
    const socketRef = useRef(null);
    const dispatch = useDispatch();
    

    const handleGenerate = async () => {
         const data = {
            consentId: careContextLinkingData.data.consentId,
            requestId: careContextLinkingData.data.consentRequestId,
        }
        console.log("hipnotify payload", data);
        await dispatch(hipNotify(data))
    }

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(socketURL);
        }
        const socket = socketRef.current;
        
        socket.on("connect", () => {
            startEmitting();
            setTimeout(async () => {
                await handleGenerate()
            }, 2000)
        });

        socket.on("getEncyptionData", (data) => {
            if (data) {
                console.log("socket data (getEncyptionData)", data);
                if (data.statusCode === 200) {
                    dispatch(addNotifications({ message: "Care Context Linking is successful", variant: TOASTER_VARIANT.SUCCESS }));
                    dispatch(setHipNotifyData(data.data));
                    stopEmitting();

                }
            }
        });


        return () => {
            if (socket) {
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    const startEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getEncyptionData");
        }
    };

    const stopEmitting = () => {
        if (socketRef.current) {
            socketRef.current.emit("getEncyptionData", { action: "stop" });
        }
    };

    return(
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
            <div className="bg-white shadow-lg rounded-lg p-4 w-full max-w-2xl max-h-screen overflow-y-auto">
                {true && 
                    <div className="flex justify-end items-center border-b pb-3">
                        <button onClick={close} className="text-gray-600 hover:text-black text-2xl">
                            ✕
                        </button>
                    </div>
                }
                { hipNotifyData ?  
                    (<div className="flex flex-col gap-4 p-2">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <div className="flex justify-center items-center w-16 h-16 bg-green-500 rounded-full">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <h1 className="font-bold text-lg text-green-600">HIP Notify initiated Successfully</h1>
                        </div>
                        <div className="flex justify-end items-end">
                            <Button 
                                onClickCb={() => onConfirm()} 
                                variant={BUTTON_VARIANTS.CONTAINED} 
                                customBtnClass={`px-4`}
                            >
                                Next
                            </Button>
                        </div>
                    </div> 
                    ):(
                    <div className="flex flex-col gap-6 justify-center items-center p-6 bg-white rounded-lg">
                        <div className="flex flex-col items-center gap-4">
                            <SandClock />
                            <h1 className="font-bold text-xl text-gray-800 text-center">
                                Hang tight! Initiating HIP Notify...
                            </h1>
                            <p className="text-sm text-gray-600 text-center">
                                This may take a moment as we sync your health records. 
                            </p>
                        </div>
                        <div className="text-center">
                            <h2 className="text-sm text-gray-700 font-medium">
                                Is it taking longer than expected? 
                            </h2>
                            <p className="text-sm text-gray-600">
                                Try initiating a new pull record and then attempt again.
                            </p>
                        </div>
                    </div>
                    )
                }
                
            </div>
        </div>
    )
}
export default HipNotifyPopup;