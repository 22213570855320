import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';
import { abhaUpdateValidation } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { componentKey } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Label from '../../../components/common-components/label/Label';


const UpdateABHADetails = ({ open, close, updateName }) => {
    const {createPatientFromAadhaar } = useSelector((state) => state[componentKey])
	const [showErrorText, setShowErrorText] = useState("");

    const validate = (values) => {
        const { loginId } = values;
        if (!loginId) {
			return {status: false, message : '*Please fill in all mandatory fields'}
        } else if (updateName === 'email' && !loginId.endsWith('@gmail.com')) {
			return {status: false, message : 'Email must end with @gmail.com'}
        } else if (
            updateName === 'mobile' &&
            (!/^[6-9]\d{9}$/.test(loginId) || loginId.length !== 10)
        ) {
			return {status: false, message : 'Mobile number must be 10 digits and start with 6-9'}
        }
        return {status: true};
    };
	const dispatch = useDispatch();
	return (
		<>
			<Formik
				initialValues={{
                    loginId: '', 
                }}
				enableReinitialize
				onSubmit={async (values) => {
					const validateRes = validate(values);
					if(!validateRes.status){
						setShowErrorText({show: true, message: validateRes.message})
						return;
					}
                    const payload = {
                        ...values,
                        "xtoken": createPatientFromAadhaar?.patient?.xtoken,
                        "loginHint":updateName
                    }
					dispatch(abhaUpdateValidation(payload));
					close(payload.loginId);
				}}
			>	
				{({
					submitForm,
					isSubmitting,
					handleReset,
					values,
					handleSubmit,
					setFieldValue,
					handleBlur,
					...props
				}) => {
					return (
						<>
							<Form onSubmit={handleSubmit}>
								<div>
									<ModalComponent
										open={open}
										title={'Update ABHA Details'}
										footerButton={
											<div className={`${showErrorText?.show ? "w-full flex items-center justify-between": ""}`}>
												{showErrorText?.show && <Label asteriskPosition="start" color="red-500" fontSize="sm">* {showErrorText?.message}</Label>}
												<Button
													type="submit"
													variant={BUTTON_VARIANTS.CONTAINED}
													customBtnClass="text-met-primary"
												>
													Generate OTP
												</Button>
											</div>
											
										}
										icon={<Icons iconName="closeIcon" />}
										close={close}
										customClasses="w-[800px]"
									>
										<div className="p-2">
											<div className="flex column-gap-10px items-center pt-6">
												<div className="flex-1">
													<div>
                                                        <Input
                                                            label={updateName === "email" ? "Email" : "Mobile Number"}
                                                            placeholder={`Please enter ${updateName === "email" ? "email" : "mobile number"}`}
                                                            isRequired={true}
                                                            name="loginId"
                                                            value={values.loginId}
                                                            onChangeCb={(e) => {
																if(updateName === "mobile" && (!/^\d*$/.test(e.target.value) || e.target.value.length > 10)) return;
                                                                setFieldValue("loginId", e.target.value);
                                                            }}
                                                            onBlurCb={handleBlur}
                                                        />
													</div>
												</div>
											</div>
										</div>
									</ModalComponent>
								</div>
							</Form>
						</>
					);
				}}
			</Formik>
			
		</>
	);
};

export default UpdateABHADetails;
