import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  ABHA_HEALTH_RECORD_HI_TYPES, ABHA_HEALTH_RECORD_NAVS, FORM_FIELDS_FOR_HEALTH_DOC, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { createHealthDocumentBundle } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import HealthRecordHelper from "../utils/utils";
import DocumentReference from "./DocumentReference";

const HealthDocumentRecord = ({onConfirm}) => {
  const { patientData, selectedBundle } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [docRefList, setDocRefList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [openHealthDocumentSuccessPopup, setOpenHealthDocumentSuccessPopup] = useState(false)
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS] || values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS].value === "Select Status") {
      errors[FORM_FIELDS_FOR_HEALTH_DOC.STATUS] = "Please select status.";
    }
    if (!values[FORM_FIELDS_FOR_HEALTH_DOC.DATE]) {
      errors[FORM_FIELDS_FOR_HEALTH_DOC.DATE] = "Please provide a date and time.";
    }
    if (!values[FORM_FIELDS_FOR_HEALTH_DOC.TITLE]) {
      errors[FORM_FIELDS_FOR_HEALTH_DOC.TITLE] = "Please provide a title.";
    }
    if(docRefList.length === 0) {
        setReportsError("Atleast 1 Document reference is required.");
    }
    return errors;
  };

  useEffect(() => {
    if(docRefList) setReportsError("");
  },[docRefList])

  useEffect(() => {
    if(selectedBundle){
      setOpenHealthDocumentSuccessPopup(true);
    }
  },[selectedBundle])

  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS].value,
        date: values[FORM_FIELDS_FOR_HEALTH_DOC.DATE],
        title: values[FORM_FIELDS_FOR_HEALTH_DOC.TITLE],
        subject: HealthRecordHelper.getStructurePatientData(patientData),
        entry: docRefList?.map((docRef) => docRef.data),
      };
      return payload;
  };

  return (
      <>
          <Formik
              initialValues={{
                    [FORM_FIELDS_FOR_HEALTH_DOC.STATUS]: { label: "Select Status", value: "Select Status" },
                    [FORM_FIELDS_FOR_HEALTH_DOC.DATE]:"",
                    [FORM_FIELDS_FOR_HEALTH_DOC.TITLE]:"",
                    [FORM_FIELDS_FOR_HEALTH_DOC.DOC_REF]:"",
              }}
              validate={validate}
              onSubmit={(values) => {
                if(docRefList.length === 0) {
                  setReportsError("Atleast 1 Document reference is required.");
                  return;
                }
                const payload = structurePayloadWithAllData(values);
                //console.log("HealthDoc", payload);
                dispatch(createHealthDocumentBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="space-y-4 h-full w-full flex flex-col justify-between">
                      <div className="grid grid-cols-2 gap-2">
                          <SelectDropdown
                              label="Composition Status"
                              name={FORM_FIELDS_FOR_HEALTH_DOC.STATUS}
                              isRequired={true}
                              customClasses="w-full"
                              value={values[FORM_FIELDS_FOR_HEALTH_DOC.STATUS]}
                              options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                              onChangeCb={(selectedOption) =>
                                  setFieldValue(FORM_FIELDS_FOR_HEALTH_DOC.STATUS, selectedOption)
                              }
                          />
                          <Input
                            label="Health Document Editing Time"
                            name={FORM_FIELDS_FOR_HEALTH_DOC.DATE}
                            type="datetime-local"
                            isRequired={true}
                            value={values[FORM_FIELDS_FOR_HEALTH_DOC.DATE]}
                            onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_HEALTH_DOC.DATE, e.target.value)}
                          />
                          <Input
                            label="Title"
                            placeholder="Human Readable name/title"
                            type="text"
                            name={FORM_FIELDS_FOR_HEALTH_DOC.TITLE}
                            isRequired={true}
                            value={values[FORM_FIELDS_FOR_HEALTH_DOC.TITLE]}
                            onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_HEALTH_DOC.TITLE, e.target.value)}
                          />
                          
                          <div className="flex flex-col gap-2 col-span-2">
                            <ItemManager
                                key={`documentRef${uuidv4()}`}
                                title={<>Document reference entry <span className="text-red-500">*</span></>} 
                                items={docRefList}
                                setItems={setDocRefList}
                                setIsPopupOpen={setIsPopupOpen}
                                customHeight="96"
                            />
                            {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                          </div>
                          
                      </div>
                      <div className="flex justify-end">
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                Submit
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
            {isPopupOpen && 
                <DocumentReference 
                    open={isPopupOpen}
                    onConfirm={setDocRefList} 
                    close={() => setIsPopupOpen(false)}
                />
            }
            <Modal
                title=""
                isOpen={openHealthDocumentSuccessPopup}
                onConfirm={() => {
                    setOpenHealthDocumentSuccessPopup(false)
                    onConfirm(ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM)
                }}
                submitText="Okay"
            >
                <div className="flex flex-col gap-4 justify-center items-center p-2">
                    <SuccessSvg/>
                    <h1 className="font-bold text-lg">Health Document Bundle created successfully</h1>
                </div>
            </Modal>
      </>
  );
};

export default HealthDocumentRecord;
