export const AbhaNumberIcon = ({ color = '#1b5984' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke={color}
            className="w-6 h-6"
        >
            <rect x="4" y="3" width="16" height="18" rx="2" ry="2" stroke={color} /> {/* Apply color to the rect stroke */}
            <circle cx="12" cy="10" r="3" stroke={color} fill={color} /> {/* Apply color to the circle stroke and fill */}
            <line x1="6" y1="16" x2="18" y2="16" stroke={color} /> {/* Apply color to the line */}
            <path
                fill={color} 
                stroke="none"
            />
        </svg>
    );
};


export const AbhaAddressIcon = ({ color = '#1b5984' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="none"
            strokeWidth="2"
            stroke={color} 
            className="w-8 h-8"
        >
            <rect x="4" y="6" width="18" height="20" rx="2" ry="2" stroke={color} /> {/* Apply color to the rect stroke */}
            <path d="M13 14v4m-2-2h4" stroke={color} strokeLinecap="round" strokeLinejoin="round" /> {/* Apply color to the path stroke */}
            <circle cx="22" cy="8" r="4" fill={color} /> {/* Apply color to the circle fill */}
            <path
                d="M21.3 7.5a1 1 0 1 1 1.4 1.4 1 1 0 0 1-1.4-1.4z"
                fill={color} 
                stroke="none"
            />
        </svg>
    );
};

  