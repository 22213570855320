// Modal.js
import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/common-components/button/Button";

const Modal = ({ title, children, isOpen, onClose, onConfirm, submitText = "Confirm", }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-md shadow-lg space-y-4 w-full sm:w-[90%] md:w-[70%] lg:w-[50%] xl:w-[40%] max-w-[600px]">
        {title && <h2 className="text-lg font-bold">{title}</h2>}
        <div>{children}</div>
        <div className="flex justify-end gap-2">
          {onClose && (<Button
            onClickCb={onClose}
            variant="outlined"
            customBtnClass="px-4 py-1"
          >
            Cancel
          </Button>
          )}
          {onConfirm && (
            <Button
              onClickCb={onConfirm}
              variant="contained"
              customBtnClass="px-4 py-1"
            >
              {submitText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Modal;
