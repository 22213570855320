import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_FIELDS, ABHA_HEALTH_RECORD_HI_TYPES, MEDICATION_REQUEST_INTENT, MEDICATION_REQUEST_STATUS } from "../Constants";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { ENCOUNTER_STATUS } from "../Constants";
import { v4 as uuidv4 } from 'uuid';

const Encounter = ({ open, close, onConfirm }) => {
    
    const validate = (values) => {
        const errors = {};
        if (!values.encounterStatus || values.encounterStatus.value === "Select Status") {
            errors.encounterStatus = "Please select a Encounter status.";
        }
        if(!values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION]){
            errors[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION] = "Please provide a classification";
        }
        return errors;
    };

    return(
        <>
            <Formik
                initialValues={{
                    encounterStatus: { label: "Select Status", value: "Select Status" },
                    [ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION]: "",
                }}
                validate={validate}
                onSubmit={(values) => {
                    const encounterData= {
                        resourceType: "Encounter",
                        id: uuidv4(),
                        status: values.encounterStatus.value,
                        class: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION],
                        type: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_TYPE],
                        serviceType:values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_SERVICE_TYPE],
                        priority: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PRIORITY],
                        period: {
                            start: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_START],
                            end: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_END],
                        },
                        reasonCode: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_REASON],
                        diagnosis: {
                            use: values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_DIAGNOSIS_USE],
                        }
                    }
                    onConfirm((prev) => [...prev, {type: `Encounter Report ${prev.length + 1}`, data: encounterData}])
                    close()
                }}
            >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                 <ModalComponent
                    open={open}
                    title={"Encounter Context"}
                    footerButton={
                        <Button
                            variant={BUTTON_VARIANTS.CONTAINED}
                            onClickCb={() => handleSubmit()}
                        >
                            Submit
                        </Button>
                    }
                    close={() => close()}
                    customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                >
                    <div className="grid grid-cols-2 gap-4">
                        <SelectDropdown
                            label="Encounter Status"
                            name="encounterStatus"
                            customClasses="w-full"
                            isRequired={true}
                            value={values.encounterStatus}
                            options={[{ label: "Select Status", value: "Select Status" }, ...ENCOUNTER_STATUS]}
                            onChangeCb={(selectedOption) =>
                                setFieldValue("encounterStatus", selectedOption)
                            }
                        />
                        <Input
                            label="Classification"
                            placeholder="Classification of patient encounter"
                            isRequired={true}
                            name={ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION}
                            value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_CLASSIFICATION,
                                    e.target.value
                                );	
                            }}
                        />
                        <Input
                            label="Encounter Type"
                            placeholder="Specific type of encounter"
                            name={ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_TYPE}
                            value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_TYPE]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_TYPE,
                                    e.target.value
                                );	
                            }}
                        />
                        <Input
                            label="Service Type"
                            placeholder="Specific type of service"
                            name={ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_SERVICE_TYPE}
                            value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_SERVICE_TYPE]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_SERVICE_TYPE,
                                    e.target.value
                                );	
                            }}
                        />
                        <Input
                            label="Priority"
                            placeholder="Enter encounter priority"
                            name={ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PRIORITY}
                            value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PRIORITY]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PRIORITY,
                                    e.target.value
                                );	
                            }}
                        />
                        <div className="col-span-2 border-2 border-gray-200 p-4 rounded-lg">
                            <h2>Period</h2>
                            <div className="flex gap-4">
                                <Input
                                    label="Start Time"
                                    placeholder="Enter start time"
                                    type="datetime-local"
                                    name = {ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_START}
                                    value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_START]}
                                    onChangeCb={(e) => 
                                        setFieldValue(
                                            ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_START,
                                            e.target.value
                                        )
                                    }
                                />
                                <Input
                                    label="End Time"
                                    placeholder="Enter end time"
                                    type="datetime-local"
                                    name = {ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_END}
                                    value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_END]}
                                    onChangeCb={(e) => 
                                        setFieldValue(
                                            ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_PERIOD_END,
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <Input
                            label="Reason"
                            placeholder="Enter reason for encounter"
                            name={ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_REASON}
                            value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_REASON]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_REASON,
                                    e.target.value
                                );	
                            }}
                        />
                        <Input
                            label="Diagnosis Use"
                            placeholder="Role that this diagnosis has within the encounter (e.g. admission, billing, discharge …)"
                            name={ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_DIAGNOSIS_USE}
                            value={values[ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_DIAGNOSIS_USE]}
                            onChangeCb={(e) => {
                                setFieldValue(
                                    ABHA_HEALTH_RECORD_FIELDS.ENCOUNTER_DIAGNOSIS_USE,
                                    e.target.value
                                );	
                            }}
                        />
                    </div>
                </ModalComponent>
            )}
        </Formik>

    </>
    )
}
export default Encounter;