import store from '../../../store/store';

export const componentKey = 'PATIENTDASHBOARD_SECTION';

const { actions } = store.reducerManager.add({
	key: componentKey,
	addedReducers: {
		setPatientId: (state, action) => {
			state.currentPatientId = action.payload;
		},
		setPatientData: (state, action) => {
			state.patientData = action.payload;
		},
		setIsOpenDeletePopup: (state, action) => {
			state.isOpenDeletePopup = action.payload;
		},
		setIsTokenGenerated: (state, action) => {
			state.isTokenGenerated = action.payload;
		},
		setGeneratedTokenData: (state, action) => {
			state.generatedTokenData = action.payload
		},
		setIscareContextLinked: (state, action) => {
			state.iscareContextLinked = action.payload;
		},
		setCareContextLinkingData: (state, action) => {
			state.careContextLinkingData = action.payload
		},
		setRecordType: (state, action) => {
			state.recordType = action.payload
		},
		setHipNotifyData: (state, action) => {
			state.hipNotifyData = action.payload
		},
		setIsABDMAcknowledged: (state, action) => {
			state.isABDMAcknowledged = action.payload
		},
		setIsDataPushedToABDM: (state, action) => {
			state.isDataPushedToABDM = action.payload
		},
		setIsHINotified: (state, action) => {
			state.isHINotified = action.payload
		},
		setSelectedBundle: (state, action) => {
			state.selectedBundle = action.payload
		},
		setDiagnosticBundle: (state, action) => {
			state.diagnosticBundle = action.payload
		},
		setDischargeSummaryBundle: (state, action) => {
			state.dischargeSummaryBundle = action.payload
		},
		setPrescriptionBundle: (state, action) => {
			state.prescriptionBundle = action.payload
		},
	},
	initialReducerState: {
		currentPatientId: '',
		patientData: {},
		isOpenDeletePopup: false,
		isTokenGenerated:false,
		generatedTokenData:null,
		iscareContextLinked:false,
		careContextLinkingData: null,
		recordType:"",
		hipNotifyData:null,
		isABDMAcknowledged:false,
		isDataPushedToABDM:false,
		isHINotified:false,
		selectedBundle: null,
		diagnosticBundle:null,
		dischargeSummaryBundle: null,
		prescriptionBundle:null,
	},
});

export const { setPatientId, setPatientData, setIsOpenDeletePopup, setIsTokenGenerated, setGeneratedTokenData, setIscareContextLinked, setCareContextLinkingData, setRecordType, setHipNotifyData, setIsABDMAcknowledged, setDiagnosticBundle, setIsDataPushedToABDM, setIsHINotified, setSelectedBundle, setDischargeSummaryBundle, setPrescriptionBundle } = actions;
