import React, { useEffect, useState } from "react";
import { ABHA_HEALTH_RECORD_NAVS } from "./Constants";
import Button from "../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";
import Label from "../../../../components/common-components/label/Label";
import { useDispatch, useSelector } from "react-redux";
import { componentKey as PatientDashboardComponentKey, setIsABDMAcknowledged } from "../PatientDashboardSlice";
import Modal from "./Modal";
import SuccessSvg from "../../../../components/icons/vectors/SuccessSvg";
import Heading from "../../../../components/common-components/heading/Heading";
import { acknowledgeABDM } from "../PatientDashboardSaga";

const AcknowledgeABDM = ({onConfirm}) => {
    const { hipNotifyData, isABDMAcknowledged, recordType  } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [isAcknowledgedABDM, setIsAcknowledgedABDM] = useState(false);
    const dispatch = useDispatch();

    const handleGenerate = async () => {
        dispatch(setIsABDMAcknowledged(false));
        const payload = {
            transactionId:hipNotifyData.transactionId,
            requestId:hipNotifyData.requestId
        }
        await dispatch(acknowledgeABDM(payload))
    }

    const clickNext = () => {
        setIsAcknowledgedABDM(false);
        onConfirm(recordType)
    }

    useEffect(() => {
        if(isABDMAcknowledged){
            setIsAcknowledgedABDM(true)
        }
    },[isABDMAcknowledged])

    return (
        <>
            <div className="flex h-full flex-col gap-2 justify-between">
                <div className="">
                    <Button 
                        onClickCb={() => handleGenerate()} 
                        variant={BUTTON_VARIANTS.CONTAINED} 
                        customBtnClass={`px-4`}
                    >
                        Acknowledge
                    </Button>
                    <Label fontSize="sm" fontWeight="bold" color="red-500">*Click on the Acknowledge button to acknowledge ABDM</Label>
                </div>                
            </div>
            <Modal
              title="Acknowledge ABDM"
              isOpen={isAcknowledgedABDM}
              onConfirm={()=> clickNext()}
              submitText="Next"
            >
                <div className="flex flex-col gap-4 justify-center items-center p-2">
                    <SuccessSvg/>
                    <Heading fontSize="lg" fontWeight="bold">Acknowledge ABDM is successfull</Heading>
                </div>
            </Modal>
        </>
    );
};

export default AcknowledgeABDM;