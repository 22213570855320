import React, { useState } from 'react';
import Button from '../../../components/common-components/button/Button';
import { ABHA_LANGUAGE_OPTIONS, ABHA_CONSENT_FORM_LANGUAGE_TEXT } from './Constants';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import SelectDropdown from '../../../components/common-components/selectDropdown';
const ConsentFormABHA = ({close, onSubmit}) => {
    const [selectedLanguage, setSelectedLanguage] = useState({ label: "English", value: "English", code: "en" });
    const [isChecked, setIsChecked] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60">
            <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">

                <div className="flex justify-between items-center border-b pb-4">
                    <div>
                        <h2 className="text-lg font-semibold">Ayushman Bharat Health Account</h2>
                        <h3 className="text-lg font-semibold">Consent Form</h3>
                    </div>
                    
                    <button onClick={close} className="text-gray-600 hover:text-black text-xl">
                        ✕
                    </button>
                </div>
                <div className='flex flex-col gap-6'>
                    <div className="flex justify-end mt-2">
                        <SelectDropdown
                            label="Select Language"
                            // placeholder="English"
                            name="language"
                            value={selectedLanguage}
                            onChangeCb={(val) => {
                                setSelectedLanguage(val);
                            }}
                            options={ABHA_LANGUAGE_OPTIONS}
                        />
                    </div>
                    <p>{ selectedLanguage.code ? ABHA_CONSENT_FORM_LANGUAGE_TEXT[selectedLanguage.code] : ABHA_CONSENT_FORM_LANGUAGE_TEXT["en"]}</p>
                    <div className="flex gap-6 items-center justify-end">
                        <Checkbox
                            label="I Agree"
                            checked={isChecked}
                            onChangeCb={(checked) => {
                                setIsChecked(checked);
                                setIsButtonEnabled(checked);
                            }}
                        />
                        <Button
                            onClickCb={() => onSubmit()}
                            disabled={!isButtonEnabled}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ConsentFormABHA;
