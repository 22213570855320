
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import store from '../../../store/store'
import PatientDataService from '../../../services/PatientDataServices'
import { componentKey, setCreatePatientData, setCreatePatientSuccessData, setDeviceNamesList, setDeviceTestList, setFetchedEmergencyContactDetails, setFetchedPatientDetails, setIsOpenAddCenterModal, setIsOpenAddInsuranceModal, setPreferencesOptions, setProviderGroupLocation, setTransactionIdAndAadhaar, setOpenImportABHADetailsModal, setOpenValidateGetOtpModal, setPatientsList, setClearOtpOrMobile, setOpenValidateCreateOtpModal, setOpenSelectPatientModal, setOpenUpdateABHAValidateOtpModal, setCreatePatientFromAadhaar, setABHAsuggestedAddress, setABHASuggestionsOpen, setABHAAddressUpdateOpen, setSelectedABHAAddress, setUpdatedABHAEmail, setUpdatedABHAMobile, setABHAProfilePicture, setDownloadedCard, setStartTimer } from './AddPatientSlice'
import ProvidersDataService from '../../../services/ProvidersDataService'
import CommmonDataService from '../../../services/CommonDataService'
import InsuranceDataService from '../../../services/InsuranceDataService'
import General from '../../../libs/utility/General'
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice'
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster'
import { setPatientData } from '../PatientDashboard/PatientDashboardSlice'
import DeviceTestDataService from '../../../services/DeviceTestDataService'
import VitalsTestDataService from '../../../services/VitalsTestDataService'
import EncounterDataService from '../../../services/EncounterDataService'
import { setIsVitalsTestModalOpen } from '../PatientDashboard/VitalsTest/VitalsTestSlice'
import { fetchAllVitalsTest } from '../PatientDashboard/VitalsTest/VitalsTestSaga'

export const { createNewPatient, getProviderGroupLocation, saveEmergencyContact, saveInsurance, getPreferences, saveClinicalCenter, savePatientPreferences, updatePatientDetails, getPatientDetails, getEmergencyContactDetails, getAllDeviceNames, getDeviceTestList, createDeviceTest, createVitalsTest, updatePatientDemographics, validateAadhaar,checkExistingAadhaar, getExistingABHADetails, abhaIntAadhaarValidation, abhaUpdateValidation, otpVerification, createABHAPatient, abhaUpdateOtpVerification, abhaAddressSuggetions, abhaAddressUpdate, abhaProfilePictureUpdate, downloadCard } = {
	createNewPatient: (payload) => {
		return {
			type: 'ADD_PATIENT/CREATE_NEW_PATIENT',
			payload
		}
	},
	getProviderGroupLocation: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_PROVIDER_GROUP_LOCATION',
			payload
		}
	},
	saveEmergencyContact: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_EMERGENCY_CONTACT',
			payload
		}
	},
	saveInsurance: (payload) => {
		return {
			type: 'ADD_PATIENT/Insurance',
			payload,
		}
	},
	getPreferences: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_PREFERENCES',
			payload
		}
	},
	saveClinicalCenter: (payload) => {
		return {
			type: "ADD_PATIENT/ADD_CLINICAL_CENTER",
			payload
		}
	},
	savePatientPreferences: (payload) => {
		return {
			type: "ADD_PATIENT/SAVE_PATIENT_CLINICAL_PREFERENCES",
			payload
		}
	},
	updatePatientDetails: (payload) => {
		return {
			type: "ADD_PATIENT/UPDATE_PATIENT_DETAILS",
			payload
		}
	},
	getPatientDetails: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_PATIENT_DETAILS',
			payload
		}
	},
	getAllDeviceNames: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_DEVICE_LIST',
			payload
		}
	},
	getDeviceTestList: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_DEVICE_TEST',
			payload
		}
	},
	createDeviceTest: (payload) => {
		return {
			type: 'ADD_PATIENT/POST_DEVICE_TEST',
			payload
		}
	},
	createVitalsTest: (payload) => {
		return {
			type: 'ADD_PATIENT/POST_VITALS_TEST',
			payload
		}
	},
	updatePatientDemographics: (payload) => {
		return {
			type: "EDIT_DEMOGAPHICS",
			payload
		}
	},
	validateAadhaar :(payload) => {
		return {
			type:'ADD_PATIENT/VALIDATE_AADHAAR',
			payload
		}
	},
	checkExistingAadhaar:(payload) => {
		return {
			type:'ADD_PATIENT/EXISTING_AADHAAR_CHECK',
			payload
		}
	},
	getExistingABHADetails: (payload) => {
		return {
			type: 'ADD_PATIENT/GET_EXISTING_ABHA_DETAILS',
			payload
		}
	},
	abhaIntAadhaarValidation :(payload) => {
		return {
			type:'ADD_PATIENT/ABHA_INT_AADHAAR_VALIDATION',
			payload
		}
	},
	abhaUpdateValidation : (payload) => {
		return {
			type:'ADD_PATIENT/ABHA_UPDATE_VALIDATION',
			payload
		}
	},
	otpVerification :(payload) => {
		return {
			type:'ADD_PATIENT/OTP_VERIFICATION',
			payload
		}
	},
	createABHAPatient : (payload) => {
		return {
			type:'ADD_PATIENT/CREATE_ABHA_PATIENT',
			payload
		}
	},
	abhaUpdateOtpVerification : (payload) => {
		return {
			type:'ADD_PATIENT/ABHA_UPDATE_OTP_VERIFICATION',
			payload
		}
	},
	abhaAddressSuggetions : (payload) => {
		return{
			type:'ADD_PATIENT/ABHA_ADDRESS_SUGGETIONS',
			payload
		}
	},
	abhaAddressUpdate : (payload) => {
		return{
			type:'ADD_PATIENT/ABHA_ADDRESS_UPDATE',
			payload
		}
	},
	abhaProfilePictureUpdate : (payload) => {
		return{
			type:'ADD_PATIENT/ABHA_PROFILE_PICTURE_UPDATE',
			payload
		}
	},
	downloadCard : (payload) => {
		return{
			type:'ADD_PATIENT/DOWNLOAD_CARD',
			payload
		}
	}
}


function* createNewPatientAsync(action) {
	try {
		const addPatient = action.payload
		const response = yield PatientDataService.createNewPatient(addPatient)
		const { data, status, message } = response.data

		if (status) {
			yield put(setCreatePatientSuccessData(data))
		}
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
		console.log('err: ', error)
	}
}

function* getProviderGroupLocationAsync(action) {
	try {
		const providerGroupLocations = action.payload;
		const response = yield ProvidersDataService.getProviderGroupLocation(providerGroupLocations);
		const { data } = response.data;

		const providerLocations = data.map((item, index) => {
			return {
				label: item.provider_locations.name,
				value: item.provider_locations.uuid,
			};
		});
		yield put(setProviderGroupLocation(providerLocations));
	} catch (error) {
		console.log('err: ', error);
	}
}

function* saveEmergencyContactAsync(action) {
	try {
		const patientEmergencyContact = action.payload.map((insurance) => {
			const { uuid, ...i } = insurance;
			return i;
		});
		yield CommmonDataService.saveEmergencyContact(patientEmergencyContact);
	} catch (error) {
		console.log('err: ', error);
	}
}

function* saveInsuranceAsync(action) {
	try {
		const patientInsuranceDetails = action.payload;
		const response = yield InsuranceDataService.saveInsuranceDetails(patientInsuranceDetails);
		const { data, success, message } = response.data;

		if (success) {
			const patientId = data[0].patientId;
			yield put(setIsOpenAddInsuranceModal(false));
			yield call(getPatientDetailsAsync, getPatientDetails({ patientId }));
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getPreferencesAsync(action) {
	try {
		const { name } = action.payload;
		const response = yield CommmonDataService.getPreferences(name);

		const { data, status, message } = response.data;
		if (status) {
			const centers = General.addLableValuePair(data[name] ?? data[name + 's'] ?? [], 'name', 'uuid');
			yield put(setPreferencesOptions({ name, data: centers ?? [] }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* saveClinicalCenterAsync(action) {
	try {
		const { name, center } = action.payload;
		const response = yield CommmonDataService.saveClinicalCenter(name, center);

		const { data, success, message } = response.data;
		if (success) {
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
			yield call(getPreferencesAsync, getPreferences({ name }));
			yield put(setIsOpenAddCenterModal(false));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* savePatientPreferencesAsync(action) {
	try {
		const { patientId, payload } = action.payload;
		const response = yield PatientDataService.savePatientPreferences(patientId, payload);

		const { data, success, message } = response.data;
	} catch (error) {
		console.log('err: ', error);
	}
}

function* updatePatientDetailsAsync(action) {
	try {
		const { patientId, payload } = action.payload;
		const response = yield PatientDataService.updatePatientDetails(patientId, payload);

		const { data, success, message } = response.data;
		if (success) {
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* getPatientDetailsAsync(action) {
	try {
		const { patientId } = action.payload
		const response = yield PatientDataService.getPatientDetails(patientId)
		const { data, status } = response.data
		if (status) {
			yield put(setFetchedPatientDetails(data))
			yield put(setPatientData(data));
		}
	} catch (error) {
		console.log('err: ', error)
	}
}

function* getAllDeviceNamesAsync(action) {
	try {
		const response = yield DeviceTestDataService.getDeviceNameList(action.payload);
		const { device, status } = response.data;
		if (status) {
			const deviceList = General.addLableValuePair(device, 'deviceName', 'uuid');
			yield put(setDeviceNamesList(deviceList));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}
function* getDeviceTestListAsync(action) {
	try {
		// const { deviceName } = action.payload
		const response = yield DeviceTestDataService.getDeviceTest(action.payload)
		const { data, status } = response.data
		if (status) {
			const deviceTestList = General.addLableValuePair(data, "testName", "uuid")
			yield put(setDeviceTestList(deviceTestList))
		}

	} catch (error) {
		console.log('err: ', error)
	}
}

function* createDeviceTestAsync(action) {
	try {
		const data = action.payload
		const response = yield DeviceTestDataService.addDeviceTest(data)
		const { status, message } = response.data
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
		console.log('err: ', error)
	}
}
function* createVitalsTestAsync(action) {
	try {
		const { data, patientId } = action.payload
		const response = yield VitalsTestDataService.postVitalsTest(patientId, data)
		const { status, message } = response.data
		if (status) {
			yield put(setIsVitalsTestModalOpen(false))
			yield put(fetchAllVitalsTest({ patientId, paginationState: { pageNumber: 1, limit: 10 } }))
		}
	} catch (error) {
		yield put(addNotifications({ message: error?.response?.data?.message, variant: TOASTER_VARIANT.ERROR }))
		console.log('err: ', error)
	}
}

function* updatePatientDemographicsAsync(action) {

	const { editDemographicsData, scheduleId, navigate } = action.payload;

	const emergencyContacts = editDemographicsData?.emergencyContact?.emergencyContacts.map((item, index) => {
		return {
			relationWithPatient: item?.relationWithPatient?.value,
			contactNumber: item?.contactNumber,
			firstName: item?.firstName,
			middleName: item?.middleName,
			lastName: item?.lastName,
			email: item?.email || undefined,
		};
	});

	const payload = {
		patientId: editDemographicsData?.patientsPreferences?.patientId,
		appointment: {
			date: editDemographicsData?.appointment?.date,
			locationId: editDemographicsData?.appointment?.locationId?.label,
			appointmentType: editDemographicsData?.appointment?.appointmentType?.value,
			providerId: editDemographicsData?.appointment?.providerId?.label,
		},
		patient: {
			firstName: editDemographicsData?.patient?.firstName,
			middleName: editDemographicsData?.patient?.middleName,
			lastName: editDemographicsData?.patient?.lastName,
			dob: editDemographicsData?.patient?.dob,
			gender: editDemographicsData?.patient?.gender,
			email: editDemographicsData?.address?.email,
			aadhar: editDemographicsData?.patient?.aadhar,
			mobileNumber: editDemographicsData?.address?.mobileNumber,
			maritalStatus: editDemographicsData?.patient?.maritalStatus,
			timeZone: editDemographicsData?.patient?.timeZone,
			homeNumber: editDemographicsData?.address?.homeNumber,
			faxNumber: editDemographicsData?.address?.faxNumber,
			profilePicture: editDemographicsData?.patient?.profilePicture,
			language: editDemographicsData?.patient?.language,
			consentToEmail: editDemographicsData?.consentToEmail,
			consentToCall: editDemographicsData?.consentToCall,
			isPatientsAadhar: true,
			consentToMessage: editDemographicsData?.consentToMessage,
			image: 'string',
		},
		address: {
			addressLine1: editDemographicsData?.address?.addressLine1,
			addressLine2: editDemographicsData?.address?.addressLine2,
			city: editDemographicsData?.address?.city,
			state: editDemographicsData?.address?.state?.label,
			country: editDemographicsData?.address?.country,
			zip: editDemographicsData?.address?.zip,
		},
		emergencyContact: emergencyContacts,
		patientsPreferences: {
			labIds: editDemographicsData?.patientsPreferences?.labIds,
			pharmacyIds: editDemographicsData?.patientsPreferences?.pharmacyIds,
			radiologyIds: editDemographicsData?.patientsPreferences?.radiologyIds,
		},
	};


	try {
		const response = yield EncounterDataService.updatePatientDemographics(scheduleId, payload);
		const { message } = response.data;
		if (response.status === 200) {
			navigate("/scheduling/list")
		}
		if (message) {
			yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error) {
		console.log('err: ', error);
	}
}

function* validateAadhaarAsync(action) {
	try {
		const data = action.payload;
		const payload = {
			aadharNumber: data,
		};
		const response = yield PatientDataService.validateAadhaar(payload);
	} catch (error) {
		if (error.response.data.statusCode === 302) {
			yield put(addNotifications({ message: 'Aadhaar number already exists !', variant: TOASTER_VARIANT.ERROR }));
		}
		console.log('err:', error);
	}
}

function* checkExistingAadhaarAsync(action) {
	try {
		const data = action.payload;
		const payload = {
			aadharNumber: data,
		};
		const response = yield PatientDataService.validateAadhaar(payload);
		if(response.status ===200){
			yield call(abhaIntAadhaarValidationAsync, abhaIntAadhaarValidation(String(data) ));
		}else{
			yield put(addNotifications({ message: 'Aadhaar number already exists !', variant: TOASTER_VARIANT.ERROR }));
		}	
	} catch (error) {
		if (error.response.data.statusCode === 302) {
			yield put(addNotifications({ message: 'Aadhaar number already exists !', variant: TOASTER_VARIANT.ERROR }));
		}
		console.log('err:', error);
	}
}

function* getExistingABHADetailsAsync(action) {
	try {
		const {getExistingABHADetailsData} = action.payload;
		const response = yield PatientDataService.getExistingABHADetails(getExistingABHADetailsData);
		const { txnId, message } = response.data
		if(response.status === 200){
			yield put(setTransactionIdAndAadhaar({transactionId:txnId}))
			if(message){
				yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			}else {
				yield put(addNotifications({message: "OTP sent", variant: TOASTER_VARIANT.SUCCESS }))
			}
			yield put(setOpenValidateGetOtpModal(true))
			yield put(setStartTimer(true))
		}

	} catch (error) {
		yield put(setOpenImportABHADetailsModal(false));
		const errorMessage =
            error?.response?.data?.message ||
            "Invalid data!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		
		console.log('err:', error);
	}
}

function* abhaIntAadhaarValidationAsync(action) {
	try {
		const data = action.payload;
		const payload = {
			aadhar: data,
		};
		const response = yield PatientDataService.abhaIntAadhaarValidation(payload);
			
		const { txnId, message } = response.data
			if(response.status === 201){
				yield put(setTransactionIdAndAadhaar({transactionId:txnId ,aadhar: data})) 
				if(message){
					yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
				}
				yield put(setOpenValidateCreateOtpModal(true))
				yield put(setStartTimer(true))
			}
	} catch (error) {
			yield put(addNotifications({ message: "Invalid Aadhaar Number", variant: TOASTER_VARIANT.ERROR }));
			console.log('err:', error);
	}
}

function* abhaUpdateValidationAsync(action) {
	try {
		const data = action.payload;
		const response = yield PatientDataService.abhaUpdateValidation(data);
		const {txnId, message} = response.data;
		if(response.status === 201){
			yield put(setTransactionIdAndAadhaar({transactionId:txnId ,aadhar: ""}))
			if(message){
				yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }))
			}
			yield put(setOpenUpdateABHAValidateOtpModal(true))
			yield put(setStartTimer(true))
		}
	} catch (error) {
		const errorMessage =
			error?.response?.data?.message ||
			"Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* otpVerificationAsync(action) {
	try {
		const {otpVerificationData} = action.payload;
		const { mobileNumber, ...otherOtpData } = otpVerificationData;
		let data = {
			...otherOtpData, 
			...(mobileNumber !== "" ? {mobile: mobileNumber}: {})
		};
		const response = yield PatientDataService.otpVerification(data);
		const {uuid} = response.data;
			if(response.status === 201){

				if( otpVerificationData.key === "mobileNumber" ) {
					yield put(setPatientsList(response.data))
					yield put(addNotifications({ message: "Patients data fetched successfully!", variant: TOASTER_VARIANT.SUCCESS }));
					yield put(setOpenSelectPatientModal(true));
				}else {
					yield put(setCreatePatientFromAadhaar(response.data)); 
					yield put(addNotifications({ message: "Patient data fetched successfully!", variant: TOASTER_VARIANT.SUCCESS }));
				}
				
				yield put(setOpenValidateCreateOtpModal(false));
				yield put(setOpenValidateGetOtpModal(false));
			}

	} catch (error) {
		yield put(setClearOtpOrMobile(true));
		const errorMessage =
			error?.response?.data?.message ||
			"Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		
		console.log('err:', error);
	}
}

function* createABHAPatientAsync(action) {
	try{
		const {createABHAPatientData} = action.payload;
		const response = yield PatientDataService.createABHAPatien(createABHAPatientData)
		if(response.status === 201){
			yield put(setCreatePatientFromAadhaar(response.data));
			yield put(setOpenSelectPatientModal(false));
			yield put (setPatientsList(null));
		}
	}catch(error) {
		yield put(addNotifications({ message: "Something went wrong !", variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* abhaUpdateOtpVerificationAsync(action) {
	try {
		const {otpVerificationData} = action.payload;
		const response = yield PatientDataService.abhaUpdateOtpVerification(otpVerificationData);
		if(response.status === 201){
			if(otpVerificationData.loginHint === "email"){
				yield put(setUpdatedABHAEmail({email: otpVerificationData.loginId}));
				yield put(addNotifications({ message: "Email updated successfully!", variant: TOASTER_VARIANT.SUCCESS }));
			}else if (otpVerificationData.loginHint === "mobile"){
				yield put(setUpdatedABHAMobile({mobile: otpVerificationData.loginId}))
				yield put(addNotifications({ message: "Mobile Number updated successfully!", variant: TOASTER_VARIANT.SUCCESS }));
			}
			yield put(setOpenUpdateABHAValidateOtpModal(false));
		}
	} catch (error) {
		yield put(setClearOtpOrMobile(true));
		const errorMessage =
			error?.response?.data?.message ||
			"Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* abhaAddressSuggetionsAsync(action) {
	try {
		const {abhaAddressSuggetionData} = action.payload;
		const response = yield PatientDataService.abhaAddressSuggetions(abhaAddressSuggetionData);
		if(response.status === 201){
			yield put(setABHAsuggestedAddress(response.data));
			yield put(setABHASuggestionsOpen(true));

		}
	} catch (error) {
		yield put(setABHAAddressUpdateOpen(true));
	}
}

function* abhaAddressUpdateAsync(action) {
	try{
		const {abhaAddressUpdateData} = action.payload;
		const response = yield PatientDataService.abhaAddressUpdate(abhaAddressUpdateData);
		if(response.status === 201){
			yield put(setSelectedABHAAddress(response.data));
			yield put(addNotifications({ message: "ABHA address updated successfully!", variant: TOASTER_VARIANT.SUCCESS }));
		}
	} catch (error){
		const {message} = error.response.data;
		yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* abhaProfilePictureUpdateAsync(action) {
	try {
		const {abhaProfilePictureUpdateData} = action.payload;
		const response = yield PatientDataService.abhaProfilePictureUpdate(abhaProfilePictureUpdateData) ;
		const { message } = response.data
		if(response.status === 200){
			yield put(setABHAProfilePicture(response.data));
			if(message){
				yield put(addNotifications({ message: message, variant: TOASTER_VARIANT.SUCCESS }));
			}
			
		}
	} catch (error) {
		yield put(addNotifications({ message: "Invalid data !", variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* downloadCardAsync(action) {
	try{
		const {downloadCardData} = action.payload;
		const response = yield PatientDataService.downloadCard(downloadCardData);
		if(response.status === 201){
			yield put(setDownloadedCard(response.data));
		}
	} catch (error){
		yield put(addNotifications({ message: "Failed to download card", variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
	}
}

function* rootSaga() {
	yield all([
		takeLatest(createNewPatient().type, createNewPatientAsync),
		takeLatest(getProviderGroupLocation().type, getProviderGroupLocationAsync),
		takeLatest(saveEmergencyContact().type, saveEmergencyContactAsync),
		takeLatest(saveInsurance().type, saveInsuranceAsync),
		takeEvery(getPreferences().type, getPreferencesAsync),
		takeLatest(saveClinicalCenter().type, saveClinicalCenterAsync),
		takeLatest(savePatientPreferences().type, savePatientPreferencesAsync),
		takeLatest(updatePatientDetails().type, updatePatientDetailsAsync),
		takeLatest(getPatientDetails().type, getPatientDetailsAsync),
		takeLatest(getAllDeviceNames().type, getAllDeviceNamesAsync),
		takeLatest(updatePatientDemographics().type, updatePatientDemographicsAsync),
		takeLatest(getDeviceTestList().type, getDeviceTestListAsync),
		takeLatest(createDeviceTest().type, createDeviceTestAsync),
		takeLatest(createVitalsTest().type, createVitalsTestAsync),
		takeLatest(validateAadhaar().type,validateAadhaarAsync),
		takeLatest(abhaIntAadhaarValidation().type,abhaIntAadhaarValidationAsync),
		takeLatest(abhaUpdateValidation().type,abhaUpdateValidationAsync),
		takeLatest(otpVerification().type,otpVerificationAsync),
		takeLatest(createABHAPatient().type, createABHAPatientAsync),
		takeLatest(abhaUpdateOtpVerification().type, abhaUpdateOtpVerificationAsync),
		takeLatest(checkExistingAadhaar().type,checkExistingAadhaarAsync),
		takeLatest(getExistingABHADetails().type,getExistingABHADetailsAsync),
		takeLatest(abhaAddressSuggetions().type, abhaAddressSuggetionsAsync),
		takeLatest(abhaAddressUpdate().type, abhaAddressUpdateAsync),
		takeLatest(abhaProfilePictureUpdate().type, abhaProfilePictureUpdateAsync),
		takeLatest(downloadCard().type, downloadCardAsync)
	])
}

store.sagaManager.addSaga(componentKey, rootSaga);
