import React from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { PROCEDURE_STATUS } from "../Constants";

const Procedure = ({ open, close, onConfirm }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.procedureStatus || values.procedureStatus.value === "Select Status") {
            errors.procedureStatus = "Please select a procedure status.";
        }
        if (!values.procedureCode) {
            errors.procedureCode = "Please provide a procedure code.";
        }
        return errors;
    };

    return (
        <>
            <Formik
                initialValues={{
                    procedureStatus: { label: "Select Status", value: "Select Status" },
                    procedureCategory: "",
                    procedureCode: "",
                    procedureReason: "",
                    bodySite:"",
                    outcome:"",
                    usedCode:""
                }}
                validate={validate}
                onSubmit={(values) => {
                    const procedureData = {
                        resourceType: "Procedure",
                        status: values.procedureStatus.value,
                        category: {
                            text: values.procedureCategory,
                        },
                        code: {
                            text: values.procedureCode,
                        },
                        reasonCode: {
                            text: values.procedureReason,
                        },
                        bodySite: {
                            text: values.bodySite,
                        },
                        outcome: {
                            text: values.outcome
                        },
                        usedCode: {
                            text: values.usedCode
                        }
                    };
                    onConfirm((prev) => [...prev, { type: `Procedure Report ${prev.length + 1}`, data: procedureData }]);
                    close();
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <ModalComponent
                        open={open}
                        title={"Add Procedure"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={() => close()}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <SelectDropdown
                                label="Procedure Status"
                                name="procedureStatus"
                                customClasses="w-full"
                                isRequired={true}
                                value={values.procedureStatus}
                                options={[{ label: "Select Status", value: "Select Status" }, ...PROCEDURE_STATUS]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("procedureStatus", selectedOption)
                                }
                            />
                            <Input
                                label="Category"
                                placeholder="Enter procedure category"
                                name="procedureCategory"
                                value={values.procedureCategory}
                                onChangeCb={(e) =>
                                    setFieldValue("procedureCategory", e.target.value)
                                }
                            />
                            <Input
                                label="Procedure Code/Text"
                                placeholder="Enter identification of the procedure"
                                name="procedureCode"
                                isRequired={true}
                                value={values.procedureCode}
                                onChangeCb={(e) =>
                                    setFieldValue("procedureCode", e.target.value)
                                }
                            />
                            <Input
                                label="Reason"
                                placeholder="Enter reason for the procedure"
                                name="procedureReason"
                                value={values.procedureReason}
                                onChangeCb={(e) =>
                                    setFieldValue("procedureReason", e.target.value)
                                }
                            />
                            <Input
                                label="Body Site"
                                placeholder="Enter body site of the procedure"
                                name="bodySite"
                                value={values.bodySite}
                                onChangeCb={(e) => setFieldValue("bodySite", e.target.value)}
                            />
                            <Input
                                label="Outcome"
                                placeholder="Enter procedure outcome"
                                name="outcome"
                                value={values.outcome}
                                onChangeCb={(e) => setFieldValue("outcome", e.target.value)}
                            />
                            <Input
                                label="Used Code/Text"
                                placeholder="Enter the code/text for the resource or device used"
                                name="usedCode"
                                value={values.usedCode}
                                onChangeCb={(e) => setFieldValue("usedCode", e.target.value)}
                            />

                        </div>
                    </ModalComponent>
                )}
            </Formik>
        </>
    );
};

export default Procedure;
