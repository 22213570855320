export const ABHA_FIELDS_NAMES = {
    SELECT_OPTION: "getOption",
    ABHA_NUMBER: "abhaNumber",
    ABHA_ADDRESS: "abhaAddress",
    AADHAR_NUMBER: "aadharNumber",
};

export const ABHA_OPTIONS = {
    GET_OPTIONS: [
        { label: "Aadhar Number", value: "Aadhar Number" },
        { label: "ABHA Number", value: "ABHA Number" },
        { label: "ABHA Address", value: "ABHA Address" },
        { label: "Mobile Number", value: "Mobile Number"},
        { label: "Scan and Share", value: "Scan and Share"}
    ],

};

export const ABHA_ERROR_MESSAGES = {
    "aadharNumber" : "Aadhar must be 12 digits",
    "abhaNumber" : "ABHA Number must be 14 digits",
    "abhaAddress1" : "ABHA Address must end with @sbx (Ex: abcd1234@sbx)",
    "abhaAddress2" : "ABHA Address must be minimum 8 and maximum 18 characters excluding '@sbx'.",
    "mobileNumber" : "Mobile number must be 10 digits and start with 6-9"
}

export const GENDER_OPTIONS = [
    {label: "M", value: "Male"},
    {label: "F", value: "Female"},
]

export const ABHA_LANGUAGE_OPTIONS = [
    { label: "English", value: "English", code: "en" },
    { label: "हिंदी", value: "हिंदी", code: "hi" },
    { label: "বাংলা", value: "বাংলা", code: "bn" },
    { label: "తెలుగు", value: "తెలుగు", code: "te" },
    { label: "मराठी", value: "मराठी", code: "mr" },
    { label: "தமிழ்", value: "தமிழ்", code: "ta" },
    { label: "اردو", value: "اردو", code: "ur" },
    { label: "ગુજરાતી", value: "ગુજરાતી", code: "gu" },
    { label: "ಕನ್ನಡ", value: "ಕನ್ನಡ", code: "kn" },
    { label: "മലയാളം", value: "മലയാളം", code: "ml" },
    { label: "ਪੰਜਾਬੀ", value: "ਪੰਜਾਬੀ", code: "pa" },
    { label: "ଓଡ଼ିଆ", value: "ଓଡ଼ିଆ", code: "or" },
    { label: "অসমীয়া", value: "অসমীয়া", code: "as" },
    { label: "کٲشُر", value: "کٲشُر", code: "ks" },
    { label: "मैथिली", value: "मैथिली", code: "mai" },
    { label: "संस्कृत", value: "संस्कृत", code: "sa" },
    { label: "कोंकणी", value: "कोंकणी", code: "kok" },
    { label: "बोड़ो", value: "बोड़ो", code: "bodo" },
    { label: "डोगरी", value: "डोगरी", code: "doi" },
    { label: "नेपाली", value: "नेपाली", code: "ne" },
    { label: "سنڌي", value: "سنڌي", code: "sd" },
    { label: "মৈতৈলোন", value: "মৈতৈলোন", code: "mni" }
];

export const ABHA_CONSENT_FORM_LANGUAGE_TEXT = {
    "en": "Are you sure you want to share your personal details to create an ABHA account?",
    "hi": "क्या आप सुनिश्चित हैं कि आप ABHA खाता बनाने के लिए अपनी व्यक्तिगत जानकारी साझा करना चाहते हैं?",
    "bn": "আপনি কি নিশ্চিত যে আপনি ABHA অ্যাকাউন্ট তৈরির জন্য আপনার ব্যক্তিগত তথ্য শেয়ার করতে চান?",
    "te": "ABHA ఖాతా సృష్టించడానికి మీ వ్యక్తిగత వివరాలను పంచుకోవాలని మీరు ఖచ్చితంగా అనుకుంటున్నారా?",
    "mr": "ABHA खाते तयार करण्यासाठी तुमची वैयक्तिक माहिती सामायिक करायची आहे याची तुम्हाला खात्री आहे का?",
    "ta": "ABHA கணக்கை உருவாக்க உங்கள் சொந்த தகவல்களை பகிர விரும்புகிறீர்களா?",
    "ur": "کیا آپ ABHA اکاؤنٹ بنانے کے لیے اپنی ذاتی تفصیلات شیئر کرنے کے بارے میں پُر یقین ہیں؟",
    "gu": "શું તમે ખાતરીપૂર્વક ABHA એકાઉન્ટ બનાવવા માટે તમારી અંગત માહિતી શેર કરવા માંગો છો?",
    "kn": "ABHA ಖಾತೆ ಸೃಷ್ಟಿಸಲು ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ನೀವು ಖಚಿತವಾಗಿದ್ದೀರಾ?",
    "ml": "ABHA അക്കൗണ്ട് സൃഷ്ടിക്കാൻ നിങ്ങളുടെ വ്യക്തിഗത വിശദാംശങ്ങൾ പങ്കിടാൻ നിങ്ങൾ ഉറപ്പാണോ?",
    "pa": "ਕੀ ਤੁਸੀਂ ABHA ਖਾਤਾ ਬਣਾਉਣ ਲਈ ਆਪਣੀ ਨਿੱਜੀ ਜਾਣਕਾਰੀ ਸਾਂਝੀ ਕਰਨ ਲਈ ਯਕੀਨੀ ਹੋ?",
    "or": "ଆପଣ ନିଶ୍ଚିତ କି ABHA ଖାତା ତିଆରି ପାଇଁ ଆପଣଙ୍କର ବ୍ୟକ୍ତିଗତ ବିବରଣୀ ସେୟାର କରିବେ?",
    "as": "আপুনি ABHA একাউণ্ট সৃষ্টি কৰিবলৈ আপোনাৰ ব্যক্তিগত বিৱৰণ ভাগ-বতৰা কৰিবলৈ নিশ্চিতনে?",
    "ks": "کیا آپ ABHA کھاتہ بنانے کے لیے اپنی ذاتی تفصیلات شیئر کرنے کے لیے یقین رکھتے ہیں؟",
    "mai": "की अहां निश्चित छी जे ABHA खाता बनेबाक लेल अहांक व्यक्तिगत जानकारी साझा करए चाहैत छी?",
    "sa": "किं भवतः निश्चयः अस्ति यत् ABHA खातं निर्मातुं स्वीयं व्यक्तिगत विवरणं साझयितुं इच्छथ?",
    "kok": "तुमका खात्री आसा की ABHA खातो तयार करपाक तुमच्या व्यक्तिगत तपशील शेअर करपाचें आसा?",
    "bodo": "ABHA एकाउन्ट फोरनोर थाखारनि नोंथायनि गोनांनि खाथामनथि गोंथायनाय नाङोनाय?",
    "doi": "की तुसीं यकीन नाल अपनी निजी जानकारी ABHA खाता बनान लेई साझी करना चाहंदे हो?",
    "ne": "के तपाईं ABHA खाता सिर्जना गर्न आफ्नो व्यक्तिगत विवरण साझा गर्न निश्चित हुनुहुन्छ?",
    "sd": "ڇا توهان کي يقين آهي ته توهان ABHA اڪائونٽ ٺاهڻ لاءِ پنهنجي ذاتي تفصيل شيئر ڪرڻ چاهيو ٿا؟",
    "mni": "ABHA খাতা তৈরিকরণত নাংগা ব্যক্তিগত বিৱৰণ সেয়াৰ তৌ? নাং নিশ্চিত তৌ?"
}
  
export const ABHA_UPDATE_OPTIONS = [
    { label: "Select update option", value:""},
    { label: 'Update ABHA Address', value: 'abhaAddress' },
    { label: 'Update Email', value: 'email' },
    { label: 'Update Mobile Number', value: 'mobile' },
    { label: 'Update Profile Picture', value: 'profilePicture'}
]

export const ABHA_VERIFICATION_OPTIONS = [
    {label: "Aadhar Link Number", value: "aadharLink"},
    {label: "ABHA Link Number", value: "mobileLink"}
]

export const FORM_FIELDS_NAMES = {
    PROFILE_PICTURE: "profilePicture",
    FIRSTNAME: 'firstName',
    MIDDLENAME: 'middleName',
    LASTNAME: "lastName",
    DOB: "dob",
    GENDER: "gender",
    MARITAL_STATUS: "maritalStatus",
    AADHAR_NUMBER: "aadhar",
    TIMEZONE: "timeZone",
    LANGUAGE: "language",
    IS_PATIENT_AADHAR: "isPatientsAadhar",
    MOBILE_NUMBER:"mobileNumber",
    OTP:"otp"
}
export const DETAILS_REVIEW_FIELDS_NAMES = {
    UPDATE_OPTION:"updateOption"
}

export const GARDIAN_FIELDS_NAMES = {
    GUARDIAN_NAME: "guardianName",
    RELATIONSHIP: "relationWithPatient",
    GUARDIAN_AADHAR_NUMBER: "guardianAadhar",
    PATIENT_DOB: "patientsBirthTimestamp",
}

export const LANGUAGE_OPTIONS = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Other", value: "Other" }
]

export const TIMEZONE_OPTIONS = [
    { label: 'IST', value: 'IST' },
    { label: 'PST', value: 'PST' },
    { label: 'EST', value: 'EST' },
    { label: 'CST', value: 'CST' },
    { label: 'MST', value: 'MST' },
    { label: 'AST', value: 'AST' },
    { label: 'HST', value: 'HST' },
];