export const ABHA_HEALTH_RECORD_FIELDS = {
    DIAGNOSTIC_REPORT_lAB: "Diagnostic Report Lab",
    MEDICATION_REQUEST: "Medication Request",
    OBSERVATION:"Observation",
    ENCOUNTER_CLASSIFICATION: "Encounter Classification",
    ENCOUNTER_TYPE: "Encounter Type",
    ENCOUNTER_SERVICE_TYPE: "Encounter Service Type",
    ENCOUNTER_PRIORITY: "Encounter Priority",
    ENCOUNTER_PERIOD_START: "Encounter Period start",
    ENCOUNTER_PERIOD_END: "Encounter Period end",
    ENCOUNTER_REASON: "Encounter Reason",
    ENCOUNTER_DIAGNOSIS_USE: "Encounter Diagnosis Use",
}
export const ABHA_HEALTH_RECORD_NAVS = {
    GENERATE_TOKEN:"Generate Token",
    CARE_CONTEXT_LINKING:"Care Context Linking",
    HIP_NOTIFY:"HIP Notify",
    ACKNOWLEDGE_ABDM: "Acknowledge ABDM",
    CREATE_BUNDLE: "Create Bundle",
    DATA_PUSH_TO_ABDM: "Date Push to ABDM",
    HEALTH_INFORMATION_NOTIFY: "Health Information Notify",
    DIAGNOSTIC_REPORT: "Diagnostic Report",
    DISCHARGE_SUMMARY: "Discharge Summary",
    HEALTH_DOCUMENT: "Health Document",
    OP_CONSULT: "OP Consult",
    PRESCRIPTION: "Prescription",
    WELLNESS: "Wellness"
}

export const ABHA_HEALTH_RECORD_HI_TYPES = {
    DIAGNOSTIC_REPORT: "Diagnostic Report",
    DISCHARGE_SUMMARY: "Discharge Summary",
    HEALTH_DOCUMENT: "Health Document",
    OP_CONSULT: "OP Consult",
    PRESCRIPTION: "Prescription",
    WELLNESS: "Wellness"
};

export const RECORD_STATUS = [
    {label: "Preliminary", value: "preliminary"},
    {label: "Final", value: "final"},
    {label: "Amended", value: "amended"},
    {label: "Entered-in-error", value: "entered-in-error"},
]

export const MEDICATION_REQUEST_STATUS = [
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Stopped", value: "stopped" },
    { label: "Draft", value: "draft" },
    { label: "Unknown", value: "unknown" },
];

export const MEDICATION_REQUEST_INTENT = [
    { label: "Proposal", value: "proposal" },
    { label: "Plan", value: "plan" },
    { label: "Order", value: "order" },
    { label: "Original Order", value: "original-order" },
    { label: "Reflex Order", value: "reflex-order" },
    { label: "Filler Order", value: "filler-order" },
    { label: "Instance Order", value: "instance-order" },
    { label: "Option", value: "option" },
];
  

export const RECORD_TYPES = [
    {label: "Diagnostic Report Lab", value: "Diagnostic Report Lab"},
    {label: "Diagnostic Report Imaging ", value: "Diagnostic Report Imaging "},
    {label: "Document Reference", value: "Document Reference"},
]

export const DIAGNOSTIC_REPORT_lAB_STATUS = [
    {label: "Registered", value: "registered"},
    {label: "Partial", value: "partial"},
    {label: "Preliminary", value: "preliminary"},
    {label: "Final", value: "final"},
]

export const OBSERVATION_STATUS = [
    {label: "Registered", value: "registered"},
    {label: "Final", value: "final"},
    {label: "Preliminary", value: "preliminary"},
    {label: "Amended", value: "amended"},
]

export const ENCOUNTER_STATUS = [
    { label: "Planned", value: "planned" },
    { label: "Arrived", value: "arrived" },
    { label: "Triaged", value: "triaged" },
    { label: "In Progress", value: "in-progress" },
    { label: "On Leave", value: "onleave" },
    { label: "Finished", value: "finished" },
    { label: "Cancelled", value: "cancelled" },
];

export const FAMILY_MEMBER_HISTORY_STATUS = [
    {label: "Partial", value: "partial"},
    {label: "Completed", value: "completed"},
    {label: "Entered-in-error ", value: "entered-in-error "},
    {label: "Health-unknown", value: "health-unknown"},
]
export const PROCEDURE_STATUS = [
    { label: "Preparation", value: "preparation" },
    { label: "In Progress", value: "in-progress" },
    { label: "Not Done", value: "not-done" },
    { label: "On Hold", value: "on-hold" },
    { label: "Stopped", value: "stopped" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" },
];

export const CARE_PLAN_STATUS = [
    { label: "Draft", value: "draft" },
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Revoked", value: "revoked" },
    { label: "Completed", value: "completed" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" }
]

export const CARE_PLAN_INTENT = [
    { label: "Proposal", value: "proposal" },
    { label: "Plan", value: "plan" },
    { label: "Order", value: "order" },
    { label: "Option", value: "option" }
]

export const GOAL_STATUS_OPTIONS = [
    { label: "Proposed", value: "proposed" },
    { label: "Planned", value: "planned" },
    { label: "Accepted", value: "accepted" },
    { label: "Active", value: "active" },
    { label: "On Hold", value: "on-hold" },
    { label: "Completed", value: "completed" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Entered in Error", value: "entered-in-error" },
    { label: "Unknown", value: "unknown" }
];

export const DOCUMENT_STATUS_OPTIONS = [
    { label: "Current", value: "current" },
    { label: "Superseded", value: "superseded" },
    { label: "Entered in Error", value: "entered-in-error" },
];

export const FORM_FIELDS_FOR_HEALTH_DOC = {
    STATUS:"healthDocStatus",
    DATE:"healthDocdate",
    TITLE:"healthDocTitle",
    DOC_REF:"healthDocRef"
}