import React, { useEffect, useMemo, useState } from 'react'
import StepperNavigation from '../../../containers/StepperNavigation'
import SucessPopUp from '../../../components/common-components/popup-components/SucessPopUp'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProviderInformation from '../../../containers/AddPatient/ProviderInformation/ProviderInformation'
import ABHAInformation from '../../../containers/AddPatient/ABHAInformation/ABHAInformation'
import PatientDetails from '../../../containers/AddPatient/PatientDetails/PatientDetails'
import EmergencyContact from '../../../containers/AddPatient/EmergencyContact.js/EmergencyContact'
import Preferences from '../../../containers/AddPatient/Preferences/Preferences'
import ContactInformation from '../../../containers/AddPatient/ContactInformation/ContactInformation'
import PrivacyConsent from '../../../containers/AddPatient/PrivacyConsent/PrivacyConsent'
import Heading from '../../../components/common-components/heading/Heading'
import { HEADING } from '../../../components/common-components/heading/Constants'
import Icons from '../../../components/icons/Icons'
import PrivacyConsentFormModal from '../../../containers/AddPatient/PrivacyConsent/PrivacyConsentFormModal'
import AddPatientInsurance from '../../../containers/AddPatient/Insurance/AddPatientInsurance'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey, setIsRedirectedFromDemographics, setNewlyAddedPatientId, setOpenValidateCreateOtpModal, setCreatePatientFromAadhaar, setDownloadedCard, setOpenABHACreateSuccessPopup, setPatientDetailsReviewLocation, setUpdatedABHAEmail, setOpenImportABHADetailsModal } from './AddPatientSlice'
import useGarbageCollector from '../../../libs/garbagecollector/garbageCollectorHooks'
import { getPatientDetails, updatePatientDemographics } from './AddPatientSaga'
import AlertPopUp from '../../../components/common-components/popup-components/AlertPopUp'
import { PATIENTS_ROUTE_PATHS } from '../../../routes/Routes'
import useGetCurrenPathHook from '../../../libs/customHooks/useGetCurrenPathHook'
import VitalsTest from '../../../containers/AddPatient/VitalsTest/VitalsTest'
import PatientAppointment from '../../../containers/AddPatient/PatientAppointment/PatientAppointment'
import General from '../../../libs/utility/General'
import PatientDetailsReviewModal from '../../../containers/AddPatient/PatientDetails/PatientDetailsReviewModal'
import ValidateOTPModal from '../../../containers/AddPatient/PatientDetails/ValidateOTPModal'
import UpdateABHADetails from '../../../containers/AddPatient/PatientDetails/UpdateABHADetails'
import UpdateABHAValidateOTPModal from '../../../containers/AddPatient/PatientDetails/UpdateABHAValidateOtpModal'

const AddPatients = () => {
    useGarbageCollector(componentKey)
    const { patientId } = useParams();
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showPatientConcentForm, setShowPatientConcentForm] = useState(false)
    const [showGetPatientDetailsPopup, setShowGetPatientDetailsPopup] = useState(false);
    const [showCreatePatientDetailsPopup, setShowCreatePatientDetailsPopup] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const scheduleId = searchParams.get('scheduleId');
    const redirectFrom = searchParams.get('redirectFrom');
    const location = useLocation()
    const [currentPathIndex, setCurrentPathIndex] = useState(0)
    const { createPatientSuccessData, isRedirectedFromDemographics, editDemographicsData, createPatientFromAadhaar,downloadedCardBuffer, openValidateCreateOtpModal, openGetOrCreatePatientModal, patientDetailsReviewLocation} = useSelector(state => state[componentKey])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const currentPath = useGetCurrenPathHook()

    const handleBackToPatient = () => {
        setShowCancelPopup(true);
    };

    useEffect(() => {
        if (patientId === ":id") {
            if (createPatientSuccessData?.patient?.uuid) {
                const currentLoc = location.pathname
                const pathWtPatientID = currentLoc.toString().replace(":id", createPatientSuccessData?.patient.uuid);
                navigate(pathWtPatientID);
            }
        }
    }, [patientId, navigate, createPatientSuccessData?.patient?.uuid])

    useEffect(() => {
        const scheduleId = searchParams.get('scheduleId');
        const redirectFrom = searchParams.get('redirectFrom');
        if (scheduleId && redirectFrom) {
            dispatch(setIsRedirectedFromDemographics(true));
        }
    }, [dispatch, searchParams])

    useEffect(() => {
        if (patientId !== ":id") {
            dispatch(setNewlyAddedPatientId(patientId))
            dispatch(getPatientDetails({ patientId }))
        }
    }, [dispatch, patientId])

    useEffect(() => {
		if (createPatientFromAadhaar !== "" && ( openGetOrCreatePatientModal === "detailsByImport" || openGetOrCreatePatientModal === "detailsByScanAndShare")) {
			setShowGetPatientDetailsPopup(true);
		} else if (createPatientFromAadhaar !== "" && openGetOrCreatePatientModal === "detailsByCreate"){
            setShowCreatePatientDetailsPopup(true);
        }
	}, [createPatientFromAadhaar]);

    const closePatientDetailsModal = () => {
        setShowGetPatientDetailsPopup(false);
        setShowCreatePatientDetailsPopup(false);
        dispatch(setOpenImportABHADetailsModal(false));
        dispatch(setCreatePatientFromAadhaar(""));
        dispatch(setDownloadedCard(null));
        dispatch(setPatientDetailsReviewLocation(""));
    }

    const getQueryRetainedPath = (url) => {
        const searchParamsObj = {};
        for (const [key, value] of searchParams.entries()) {
            searchParamsObj[key] = value;
        }
        const urlWithParams = `${url}?${General.convertObjToQueryString(searchParamsObj)}`
        return urlWithParams
    }

    const ADD_PATIENT_STEPPER_STEPS = useMemo(
        () => [
            {
                icon:"r",
                label: 'ABHA Information',
                component: ABHAInformation,
                backButtonText:'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Next',
                submitCb: () => { },
                path:getQueryRetainedPath(PATIENTS_ROUTE_PATHS.ABHA_INFORMATION),
                scheduleId:scheduleId
            },
            ...(isRedirectedFromDemographics ?
                [{
                    icon: "r",
                    label: 'Appointment Details',
                    component: PatientAppointment,
                    backButtonText: 'Cancel',
                    prevCb: handleBackToPatient,
                    submitButtonText: 'Save & Next',
                    submitCb: () => { },
                    path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.PROVIDER_INFORMATION),
                    // scheduleId:scheduleId
                }]
                :
                [
                    {
                        icon: "r",
                        label: 'Provider Information',
                        component: ProviderInformation,
                        backButtonText: 'Cancel',
                        prevCb: handleBackToPatient,
                        submitButtonText: 'Save & Next',
                        submitCb: () => {

                        },
                        path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.PROVIDER_INFORMATION),
                        // scheduleId:scheduleId

                    }
                ]
            ),
            {
                icon: "r",
                label: 'Patient Details',
                component: PatientDetails,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Next',
                submitCb: () => { },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.PATIENT_DETAILS),
                scheduleId: scheduleId

            },
            {
                icon: "r",
                label: 'Contact Information',
                component: ContactInformation,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Next',
                submitCb: () => { },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.CONTACT_INFORMATION),
                scheduleId: scheduleId

            },
            {
                icon: "r",
                label: 'Emergency Contact',
                component: EmergencyContact,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Next',
                submitCb: () => { },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.EMERGENCY_CONTACT),
                scheduleId: scheduleId

            },
            {
                icon: "r",
                label: 'Insurance',
                component: AddPatientInsurance,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Next',
                submitCb: () => { },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.INSURANCE),
                isSkippable: true,
                scheduleId: scheduleId

            },
            {
                icon: "r",
                label: 'Vitals Test',
                component: VitalsTest,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Next',
                submitCb: () => { },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.VITALS_TEST),
                scheduleId: scheduleId

            },
            {
                icon: "r",
                label: 'Preferences',
                component: Preferences,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Next',
                submitCb: () => { },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.PREFERENCES),
                scheduleId: scheduleId

            },
            {
                icon: "r",
                label: 'Privacy Consent',
                component: PrivacyConsent,
                backButtonText: 'Cancel',
                prevCb: handleBackToPatient,
                submitButtonText: 'Save & Finish',
                submitCb: () => {
                    if (isRedirectedFromDemographics) {
                        dispatch(updatePatientDemographics({ editDemographicsData: editDemographicsData, scheduleId: scheduleId, navigate: navigate }))
                    } else {

                        setShowPatientConcentForm(true);
                    }
                },
                path: getQueryRetainedPath(PATIENTS_ROUTE_PATHS.CONSENTS),
                scheduleId: scheduleId

            },
        ], [isRedirectedFromDemographics, editDemographicsData, dispatch, scheduleId]);

    useEffect(() => {
        let currentPathIndex = ADD_PATIENT_STEPPER_STEPS.findIndex(item => item.path === getQueryRetainedPath(currentPath))
        setCurrentPathIndex(currentPathIndex)
    }, [currentPath])

    return (
        <>
            <div className='flex flex-col row-gap-15'>
                <Heading fontSize="2xl" onClickCb={() => setShowCancelPopup(true)} fontWeight="bold" type={HEADING.H1} customClasses="flex column-gap-10px items-center cursor-pointer">
                    <Icons iconName={"arrowBackIcon"} /> {isRedirectedFromDemographics ? "Check In" : " Add Patient"}
                </Heading>
                <StepperNavigation parentPath={`/patients/add/${patientId}`}
                    isNavigationUrl={true}
                    steps={ADD_PATIENT_STEPPER_STEPS}
                    activeTabIndex={currentPathIndex} />
            </div>
            {showCancelPopup ?
                <AlertPopUp open={showCancelPopup}
                    cancelBtnProps={{
                        onCancelCb: () => setShowCancelPopup(false)
                    }}
                    confirmationButtonProps={{
                        onConfirmCb: () => {
                            setShowCancelPopup(false)
                            if (isRedirectedFromDemographics) {
                                navigate("/scheduling/list")
                            } else {
                                navigate("/patients")
                            }
                        }
                    }}
                /> : null}
            {showSuccessPopup ? <SucessPopUp
                successMesaage="Patient Details Added Successfully"
                isOpen={showSuccessPopup}
                onClickOk={() => {
                    setShowSuccessPopup(false)
                    navigate("/patients")
                }}
            >
                <Icons iconName="successSvg" />
            </SucessPopUp> : null}
            {showPatientConcentForm && <PrivacyConsentFormModal open={showPatientConcentForm} close={() => {
                setShowPatientConcentForm(false)
            }} onClickCb={() => {
                setShowSuccessPopup(true)
                setShowPatientConcentForm(false)

            }} />}

            { openValidateCreateOtpModal ? 
				<ValidateOTPModal
					open={openValidateCreateOtpModal}
					close={() =>  {
                        dispatch(setOpenValidateCreateOtpModal(false))}
					}
				/> : null
			}
            {showGetPatientDetailsPopup && createPatientFromAadhaar && (
                <PatientDetailsReviewModal
                    open={showGetPatientDetailsPopup}
                    onSubmit={() => {
                        const currentLoc = location.pathname
                        const pathWtPatientID = currentLoc.toString().replace(":id", createPatientFromAadhaar?.patient?.uuid);
                        navigate(pathWtPatientID);
                        closePatientDetailsModal();
                    }}
                    close={() => closePatientDetailsModal()}
                />
            )}
            {showCreatePatientDetailsPopup && createPatientFromAadhaar && (
                <PatientDetailsReviewModal
                    open={showCreatePatientDetailsPopup}
                    onSubmit={() => {
                        if(patientDetailsReviewLocation === "abhaInformationTab"){
							dispatch(setOpenABHACreateSuccessPopup(true));
                        }else{
                            const currentLoc = location.pathname
                            const pathWtPatientID = currentLoc.toString().replace(":id", createPatientFromAadhaar?.patient?.uuid);
                            navigate(pathWtPatientID);
                        }
                        closePatientDetailsModal();
                    }}
                    close={() => {
                        dispatch(setOpenABHACreateSuccessPopup(false));
                        closePatientDetailsModal()
                    }}
                />
            )}
        </>
    )
}

export default AddPatients