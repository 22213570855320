import React, { useState } from "react";
import Input from "../../../../components/common-components/input/Input";
import Button from "../../../../components/common-components/button/Button";
import Label from "../../../../components/common-components/label/Label";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";

const ItemManager = ({
  title ,
  items = [],
  setItems,
  setIsPopupOpen,
  customClass,
  customHeight
}) => {

  const handleRemoveItem = (index) => {
    setItems((prev) => prev.filter((_, idx) => idx !== index));
  };

  return (
    <>
      <div className={`w-full border-2 border-gray-300 p-4 rounded-md shadow-sm min-h-[125px] max-h-${customHeight} overflow-y-auto ${customClass}`}>
        <div className="flex justify-between items-center mb-4">
          <Label fontSize="lg" fontWeight="bold">{title}</Label>
          <Button
            onClickCb={() => setIsPopupOpen(true)}
            variant={BUTTON_VARIANTS.CONTAINED}
            customBtnClass="px-4 py-1"
          >
            Add
          </Button>
        </div>
        <div>
          {items.length > 0 ? (
            <ul className="space-y-2">
              {items.map((item, index) => (
                <li
                  key={index + title}
                  className="flex justify-between items-center bg-gray-100 px-4 py-2 rounded-md shadow-sm"
                >
                  <p className="text-sm font-medium text-gray-700">{item.type}</p>
                  { item?.recievedTime && <p className="text-sm font-medium text-gray-700">{item.recievedTime.split("T").join('/')}</p>}
                  <Button
                    onClickCb={() => handleRemoveItem(index)}
                    variant={BUTTON_VARIANTS.OUTLINED}
                    customBtnClass="px-2 py-1 text-red-500 border-red-500"
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-gray-500">No data added yet.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemManager;
