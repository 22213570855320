import React, { useState, useEffect } from 'react';
import Button from '../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setABHAAddressUpdateOpen, setABHAProfilePicture, setABHAsuggestedAddress, setABHASuggestionsOpen,setDownloadedCard,setOpenUpdateABHAValidateOtpModal, setSelectedABHAAddress, setUpdatedABHAEmail, setUpdatedABHAMobile } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Input from '../../../components/common-components/input/Input';
import Avatar from '../../../components/common-components/avatar';
import TextArea from '../../../components/common-components/textArea/TextArea';
import { abhaAddressSuggetions, abhaAddressUpdate, downloadCard } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import UpdateABHAAddressPopup from './updateABHAAddressPopup';
import General from '../../../libs/utility/General';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import { ABHA_UPDATE_OPTIONS } from './Constants';
import { Formik, Form } from 'formik';
import UpdateABHADetails from './UpdateABHADetails';
import UpdateABHAValidateOTPModal from './UpdateABHAValidateOtpModal';
import UploadProfilePictureModal from './UploadProfilePictureModal';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import Label from '../../../components/common-components/label/Label';
import UpdateABHAAddressModal from './UpdateABHAAddressModal';

function PatientDetailsReviewModal({ open, onSubmit, close }) {
    const { createPatientFromAadhaar, openGetOrCreatePatientModal, transactionIdAndAadhaar, abhaSuggestedAddress, abhaSuggestionsOpen, abhaAddressUpdateOpen, selectedABHAAddress, updatedABHAEmail, updatedABHAMobile, abhaProfilePicture, openUpdateABHAValidateOtpModal, downloadedCardBuffer } = useSelector(state => state[componentKey]);
    const dispatch = useDispatch();
    const {
        firstName,
        middleName,
        lastName,
        email,
        profilePicture,
        dob,
        gender,
        mobileNumber,
        aadhar,
        abhaNumber
    } = createPatientFromAadhaar?.patient || {};
    const address = createPatientFromAadhaar?.address || "";
    const [abhaAddressValue, setABHAAddressValue] = useState("");
    const [confirmUpdateABHAAddressOpen, setConfirmUpdateABHAAddressOpen] = useState(false);
    const [abhaAddressToDisplay, setAbhaAddressToDisplay] = useState("");
    const [abhaEmailToDispplay, setABHAEmailToDisplay] = useState("");
    const [abhaMobileToDispplay, setABHAMobileToDisplay] = useState("");
    const [abhaProfilePictureToDisppay, setABHAProfilePictureToDisplay] = useState("");
    const [updateOption, setUpdateOption] = useState({ label: "Select update option", value:""});
    const [updateInputValue, setUpdateInputValue] = useState("");
    const [openUpdateABHAOtpRequest, setOpenUpdateABHAOtpRequest] = useState("false");
    const [openEditProfilePicture, setOpenEditProfilePicture] = useState(false);

    useEffect(() => {
        if (createPatientFromAadhaar?.patient?.abhaAddress) {
            setAbhaAddressToDisplay(createPatientFromAadhaar.patient.abhaAddress);
        }
        if(createPatientFromAadhaar?.patient?.mobileNumber) {
            setABHAMobileToDisplay(createPatientFromAadhaar?.patient?.mobileNumber)
        }
        if(createPatientFromAadhaar?.patient?.email) {
            setABHAEmailToDisplay(createPatientFromAadhaar?.patient?.email)
        }
        if(createPatientFromAadhaar?.patient?.profilePicture){
            setABHAProfilePictureToDisplay(createPatientFromAadhaar?.patient?.profilePicture)
        }
        return () => {
            dispatch(setABHAsuggestedAddress(""))
            dispatch(setABHASuggestionsOpen(false));
            dispatch(setSelectedABHAAddress(""))
            dispatch(setUpdatedABHAEmail(""))
            dispatch(setUpdatedABHAMobile(""))
            dispatch(setABHAProfilePicture(null))
            dispatch(setDownloadedCard(null))
            dispatch(setABHAAddressUpdateOpen(false))
        }
    }, [createPatientFromAadhaar]);
    
    useEffect(() => {
        if (selectedABHAAddress?.preferredAbhaAddress) {
            setAbhaAddressToDisplay(selectedABHAAddress.preferredAbhaAddress);
        }
    }, [selectedABHAAddress]);

    useEffect(() => {
        if(updatedABHAEmail?.email){
            setABHAEmailToDisplay(updatedABHAEmail.email)
        }
    }, [updatedABHAEmail])

    useEffect(() => {
        if(updatedABHAMobile?.mobile){
            setABHAMobileToDisplay(updatedABHAMobile.mobile)
        }
    }, [updatedABHAMobile])

    useEffect(() => {
        if(abhaProfilePicture?.data?.profilePicture) {
            setABHAProfilePictureToDisplay(abhaProfilePicture?.data?.profilePicture)
        } else if(abhaProfilePicture?.encodeBase64) {
            setABHAProfilePictureToDisplay(abhaProfilePicture?.encodeBase64)
        }
    }, [abhaProfilePicture])

    const handleUpdateABHAAddress = async( abhaAddress) => {
        try{
            const payload = {
                txnId: transactionIdAndAadhaar?.transactionId || '',
                abhaAddress: abhaAddress

            }
            await dispatch(abhaAddressUpdate({abhaAddressUpdateData:payload}))

        } catch (error){
            console.log("Error in suggested address", error)
        } finally {
            setUpdateOption({ label: "Select update option", value:""})
            dispatch(setABHAAddressUpdateOpen(false))
            setConfirmUpdateABHAAddressOpen(false)
            dispatch(setABHASuggestionsOpen(false));
        }
    }
    
    const handleSelectAddress = (abhaAddress) => {
        setConfirmUpdateABHAAddressOpen(true);
        setABHAAddressValue(abhaAddress)
    };
    
    const handleSuggestedABHAAddress = async() => {
        const makeAPICall = !abhaSuggestionsOpen;
        if(makeAPICall){
            try{
                const payload = {
                    txnId: transactionIdAndAadhaar?.transactionId || '',
                }
                await dispatch(abhaAddressSuggetions({abhaAddressSuggetionData:payload}))
                
            } catch (error){
                console.log("Error in suggested address", error)
            }
        }else{
            dispatch(setABHASuggestionsOpen(false));
        }
    }

    const handleDownloadCard = async () => {
        try {
            const payload = {
                xtoken : createPatientFromAadhaar?.patient?.xtoken || '',
            };
            await dispatch(downloadCard({downloadCardData: payload}));
        } catch (error) {
            console.error("Error downloading card:", error);
        }
    };

    useEffect(() => {
        const handleDownloadCard = async (bufferData) => {
            await General.downloadFile(bufferData, 'Patient_Card');
            
        };

        if (downloadedCardBuffer !== null) {
            handleDownloadCard(downloadedCardBuffer.data);
        }
    }, [downloadedCardBuffer]);
    
    function formatDOB(dob) {
        if (typeof dob !== "string") {
          return dob;
        }
      
        const isoDate = Date.parse(dob);
        if (!isNaN(isoDate)) {
          return new Date(isoDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        }

        const [day, month, year] = dob.split('-').map(Number);
        if (day && month && year) {
          const parsedDate = new Date(year, month - 1, day);
          return parsedDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
        }
        return dob;
      }

    if (!open) {
        return null;
    }

    return(
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                gender: '',
                mobileNumber: '',
                updateOption: '',
            }}
            onSubmit={() => {}}
        >
            {({ values, errors, touched, handleChange }) => {
            return (
                <>
                    <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-5xl max-h-screen overflow-y-auto">
                            <div className="flex justify-between items-center border-b pb-3">
                                <h2 className="text-lg font-semibold">Ayushman Bharat Health Authority Details</h2>
                                <button onClick={close} className="text-gray-600 hover:text-black text-xl">
                                    ✕
                                </button>
                            </div>

                            <div className="mt-4 space-y-4">
                                <div className="flex flex-col gap-4">
                                    <div className='flex justify-between'>
                                        <div className="col-span-1 flex flex-col items-left">
                                            {profilePicture ? (
                                                abhaProfilePicture?.encodeBase64 ? (
                                                        <img
                                                            alt="ABHA Profile"
                                                            src={`data:image/png;base64,${abhaProfilePicture.encodeBase64}`}
                                                            className="w-[130px] h-[130px] object-cover rounded-full"
                                                        />
                                                    ) :  
                                                    <Avatar textCenter="middle"
                                                        customClass="w-[130px] h-[130px] overflow-hidden object-cover rounded-full"
                                                        fontSize={3} url={abhaProfilePictureToDisppay} name={`${firstName} ${lastName}`} 
                                                    />
                                                ) : (
                                                    <div className="w-32 h-32 border rounded-full border-gray-300 flex items-center justify-center">
                                                        <span 
                                                            className="text-met-primary text-6xl font-bold whitespace-nowrap overflow-hidden text-ellipsis"
                                                            style={{ maxWidth: '80%' }}
                                                        >
                                                            {firstName.charAt(0).toUpperCase()}
                                                            {lastName.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {openGetOrCreatePatientModal !== "detailsByScanAndShare" &&
                                            <div className='flex gap-5 items-start'>
                                                {openGetOrCreatePatientModal ==="detailsByCreate" &&
                                                    <div className='relative'>
                                                        <SelectDropdown
                                                            name='updateABHADetails'
                                                            value={updateOption}
                                                            onChangeCb={(val) => {
                                                                setUpdateOption(val);
                                                                if(val.value !== "abhaAddress") dispatch(setABHASuggestionsOpen(false));
                                                                switch (val.value) {
                                                                    case 'abhaAddress':
                                                                        handleSuggestedABHAAddress();
                                                                        break;
                                                                    case 'email':
                                                                        setOpenUpdateABHAOtpRequest(true);
                                                                        break;
                                                                    case 'mobile':
                                                                        setOpenUpdateABHAOtpRequest(true);
                                                                        break;
                                                                    case 'profilePicture':
                                                                        setOpenEditProfilePicture(true);
                                                                        break;
                                                                    default:
                                                                        console.log("No action selected");
                                                                }
                                                            }}
                                                            options={ABHA_UPDATE_OPTIONS}
                                                        />
                                                        {abhaSuggestionsOpen && (
                                                            <div className="mt-4 bg-white absolute border border-gray-300 z-10 top-7 left-0 rounded-lg overflow-hidden">
                                                                <div className='flex justify-end '>
                                                                    <button onClick={() => {
                                                                            dispatch(setABHASuggestionsOpen(false))
                                                                            setUpdateOption({ label: "Select update option", value:""})
                                                                        }} 
                                                                        className=" text-gray-600 hover:text-black text-xl pt-2 px-2"
                                                                    >
                                                                        ✕
                                                                    </button>
                                                                </div>
                                                                <div className="flex px-4 ">
                                                                    <Checkbox
                                                                        checked={abhaAddressUpdateOpen}
                                                                        onChangeCb={() => {
                                                                            dispatch(setABHAAddressUpdateOpen(true))
                                                                        }}
                                                                    />
                                                                    <div className="pl-1 text-gray-500 ">
                                                                        <Label fontWeight="semibold" color={"met-primary"}>Enter Manually</Label>
                                                                    </div>
                                                                </div>
                                                                <ul className=" rounded p-2 space-y-2">
                                                                    {abhaSuggestedAddress?.abhaAddressList?.length > 0 && (
                                                                        abhaSuggestedAddress.abhaAddressList.slice(0,3).map((address, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className="cursor-pointer p-2 rounded hover:bg-met-primary hover:text-white"
                                                                                onClick={() => handleSelectAddress(address)}
                                                                            >
                                                                                {address}@sbx
                                                                            </li>
                                                                        ))
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                <Button
                                                    variant={BUTTON_VARIANTS.CONTAINED}
                                                    onClickCb={handleDownloadCard}
                                                    className="text-blue-600 border-blue-600"
                                                >
                                                    Download Card
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="col-span-2 grid md:grid-cols-3 gap-4">
                                        <Input 
                                            label={<>First Name <span className="text-red-500">*</span></>} 
                                            value={firstName || ''} 
                                            name='firstName'
                                            disabled
                                        />
                                        <Input 
                                            label="Middle Name" 
                                            value={middleName || ''} 
                                            name='middleName'
                                            disabled
                                        />
                                        <Input 
                                            label={<>Last Name <span className="text-red-500">*</span></>} 
                                            value={lastName || ''} 
                                            name='lastName'
                                            disabled 
                                        />
                                        { abhaEmailToDispplay !== "" &&
                                            <Input 
                                                label={<>Email <span className="text-red-500">*</span></>} 
                                                value={abhaEmailToDispplay || ''} 
                                                name='email'
                                                disabled
                                            />
                                        }
                                        <Input 
                                            label={<>Date of Birth <span className="text-red-500">*</span></>} 
                                            value={dob ? formatDOB(dob) : ''} 
                                            name='dob'
                                            disabled 
                                        />
                                        <Input
                                            label={<>Gender <span className="text-red-500">*</span></>}
                                            value={gender?.charAt(0).toUpperCase() + gender?.slice(1) || ''}
                                            name='gender'
                                            disabled
                                            
                                        />
                                        <Input 
                                            label={<>Mobile <span className="text-red-500">*</span></>} 
                                            value={abhaMobileToDispplay || ''} 
                                            name='mobileNumber'
                                            disabled 
                                        />
                                        <Input 
                                            label={<>ABHA Number <span className="text-red-500">*</span></>} 
                                            value={abhaNumber || ''} 
                                            name='abhaNumber'
                                            disabled 
                                        />
                                        {abhaAddressToDisplay !== "" && 
                                            <Input
                                                label={<>ABHA Address <span className="text-red-500">*</span></>} 
                                                value={abhaAddressToDisplay || ''}
                                                name='abhaAddress'
                                                disabled
                                            />
                                        }
                                    </div>
                                    <div className='w-full'>
                                        <TextArea
                                            label={<>Address <span className="text-red-500">*</span></>} 
                                            customClasses="bg-[#f7f6f6]"
                                            value={
                                                address
                                                    ? `${address.addressLine1 || ''}, ${address.addressLine2 || ''}, ${address.city || ''}, ${address.state || ''}, ${address.country || ''}, ${address.zip || ''}`
                                                    : 'No Address Available'
                                            }
                                            disabled
                                            name='address'
                                        />
                                    </div>
                                    

                                </div>

                                <p className="text-xs text-red-500">* Indicates mandatory fields</p>
                            </div>


                            <div className="flex justify-end space-x-4 mt-6">
                                <Button
                                    variant={BUTTON_VARIANTS.CONTAINED}
                                    onClickCb={onSubmit}
                                    className="text-blue-600 border-blue-600"
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>

                    {confirmUpdateABHAAddressOpen && 
                        <UpdateABHAAddressPopup 
                            open={confirmUpdateABHAAddressOpen} 
                            close={() => {
                                setConfirmUpdateABHAAddressOpen(false)
                                //setUpdateOption({ label: "Select update option", value:""})
                            }}
                            onConfirm={() => handleUpdateABHAAddress(abhaAddressValue)}
                        />
                    }

                    {updateOption.value === "email" || updateOption.value === "mobile" ? (
                        <UpdateABHADetails
                            open={openUpdateABHAOtpRequest}
                            close={(value) => {
                                setUpdateInputValue(value);
                                setOpenUpdateABHAOtpRequest(false);
                                if(typeof value !== 'string'){
                                    setUpdateOption({ label: "Select update option", value:""})
                                }
                                
                            }}
                            updateName={updateOption.value}
                        />
                    ) : null}

                    {abhaAddressUpdateOpen &&
                        <UpdateABHAAddressModal 
                            open={abhaAddressUpdateOpen}
                            close={() => {
                                dispatch(setABHAAddressUpdateOpen(false))
                            }}
                            onConfirm = {(value) => {
                                handleUpdateABHAAddress(value)
                                setUpdateOption({ label: "Select update option", value:""})
                            }}
                        />
                    }
                    {openUpdateABHAValidateOtpModal && 
                        <UpdateABHAValidateOTPModal 
                            open={openUpdateABHAValidateOtpModal}
                            updateInputValue={updateInputValue}
                            close={() => {
                                setUpdateOption({ label: "Select update option", value:""})
                                dispatch(setOpenUpdateABHAValidateOtpModal(false))
                                setUpdateInputValue("");
                            }}
                            updateName={updateOption.value}
                        />
                    }
                    {openEditProfilePicture && 
                        <UploadProfilePictureModal 
                            open={openEditProfilePicture}
                            close={() => {
                                setUpdateOption({ label: "Select update option", value:""})
                                setOpenEditProfilePicture(false)
                            }}
                        />
                    }
                </>
            );
        }}
        </Formik>
    )
}

export default PatientDetailsReviewModal;
