import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  ABHA_HEALTH_RECORD_HI_TYPES, ABHA_HEALTH_RECORD_NAVS, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { createPrescriptionBundle } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";
import HealthRecordHelper from "../utils/utils";
import MedicationRequest from "./MedicationRequest";

const PrescriptionRecord = ({onConfirm}) => {
  const { patientData, prescriptionBundle } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [reportsList, setReportsList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [openPrescriptionSuccessPopup, setOpenPrescriptionSuccessPopup] = useState(false)
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
      errors.compositionStatus = "Please select composition status.";
    }
    if (!values.prescriptionDate) {
      errors.prescriptionDate = "Please provide a date and time.";
    }
    if (!values.prescriptionTitle) {
      errors.prescriptionTitle = "Please provide a title.";
    }
    return errors;
  };

  useEffect(() => {
    if(reportsList) setReportsError("");
  },[reportsList])

  useEffect(() => {
    if(prescriptionBundle){
      setOpenPrescriptionSuccessPopup(true);
    }
  },[prescriptionBundle])

  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values.compositionStatus.value,
        date: values.prescriptionDate,
        title: values.prescriptionTitle,
        subject: HealthRecordHelper.getStructurePatientData(patientData),
        entry: reportsList?.map((report) => {
          return HealthRecordHelper.getStructuredMedicationRequestData(report.medicationRequestReport)
        }),
      };
      return payload;
  };

  return (
      <>
          <Formik
              initialValues={{
                  compositionStatus:{ label: "Select Status", value: "Select Status" },
                  recordType: { label: "Select Record Type", value: "Select Record Type" },
                  prescriptionDate:"",
                  prescriptionTitle:"",
              }}
              validate={validate}
              onSubmit={(values) => {
                if(reportsList.length === 0) {
                  setReportsError("Atleast 1 report is required.");
                  return;
                }
                const payload = structurePayloadWithAllData(values);
                dispatch(createPrescriptionBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="space-y-4 h-full w-full flex flex-col justify-between">
                      <div className="grid grid-cols-2 gap-2">
                          <SelectDropdown
                              label="Composition Status"
                              name="compositionStatus"
                              isRequired={true}
                              customClasses="w-full"
                              value={values.compositionStatus}
                              options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                              onChangeCb={(selectedOption) =>
                                  setFieldValue("compositionStatus", selectedOption)
                              }
                          />
                          <Input
                            label="Prescription Editing Time"
                            name="prescriptionDate"
                            type="datetime-local"
                            isRequired={true}
                            value={values.prescriptionDate}
                            onChangeCb={(e) => setFieldValue("prescriptionDate", e.target.value)}
                          />
                          <Input
                            label="Title"
                            placeholder="Human Readable name/title"
                            type="text"
                            name="prescriptionTitle"
                            isRequired={true}
                            value={values.prescriptionTitle}
                            onChangeCb={(e) => setFieldValue("prescriptionTitle", e.target.value)}
                          />
                          
                          <div className="flex flex-col gap-2 col-span-2">
                            <ItemManager
                                title={<>Report entry <span className="text-red-500">*</span></>} 
                                items={reportsList}
                                setItems={setReportsList}
                                setIsPopupOpen={setIsPopupOpen}
                                customHeight="96"
                            />
                            {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                          </div>
                          
                      </div>
                      <div className="flex justify-end">
                          <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                              Submit
                          </Button>
                      </div>
                  </form>
              )}
          </Formik>
          {isPopupOpen && <MedicationRequest onConfirm={setReportsList} close={() => setIsPopupOpen(false)}/>}
          <Modal
              title=""
              isOpen={openPrescriptionSuccessPopup}
              onConfirm={() => {
                setOpenPrescriptionSuccessPopup(false)
                onConfirm(ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM)
              }}
              submitText="Okay"
          >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                <SuccessSvg/>
                <h1 className="font-bold text-lg">Prescription Bundle created successfully</h1>
            </div>
          </Modal>
      </>
  );
};

export default PrescriptionRecord;
