import React, { useState, useEffect, useRef } from 'react';
import './StepperNavigationStyles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/common-components/button/Button';
import RoundFab from '../../components/common-components/Fab/RoundFab';
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants';
import Icons from '../../components/icons/Icons';
import Label from '../../components/common-components/label/Label';
import Checkbox from '../../components/common-components/checkbox/Checkbox';
import { componentKey, setIsRedirectedFromDemographics, setNewlyAddedPatientId, setOpenABHACreateSuccessPopup } from '../../pages/Patients/AddPatient/AddPatientSlice'
import { useDispatch, useSelector } from 'react-redux';
import SucessPopUp from '../../components/common-components/popup-components/SucessPopUp';

const StepperNavigation = ({ steps, isNavigationUrl, parentPath, activeTabIndex }) => {
	const { openABHACreateSuccessPopup } = useSelector(state => state[componentKey])
	const [activeStep, setActiveStep] = useState(0);
	const [isActiveStepSkippable, setIsActiveStepSkippable] = useState(false)
	const formRef = useRef()
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const handleNextButtonClick = async (step, index) => {
		if (step.submitCb) {
			step.submitCb();
		}
		if (formRef?.current) {
			await formRef.current.submitForm();

			const isValid = formRef.current.isValid
			if (!isValid) {
				return
			}
		}
		if (index < steps.length - 1) {
			if (isNavigationUrl) {
				navigate(parentPath + steps[index + 1].path);
			}
			setActiveStep((prev) => prev + 1);
		}
	};

	useEffect(() => {
		isNavigationUrl ? setActiveStep(activeTabIndex) : setActiveStep(0);
	}, [activeTabIndex, isNavigationUrl]);


	return (
		<div>
			<div className="flex items-center m-1 justify-between my-2">
				{steps.map((step, index) => {
					return (
						<React.Fragment key={`stepper-navigation-step-${step.label}-${index}`}>
							{isNavigationUrl ? (
								<Link to={activeStep > index && `${parentPath}${step.path}`} className="flex-1 text-decoration-none">
									<div
										className={`flex flex-1 items-center p-3 pointer space-x-4 border-b ${activeStep === index ? 'border-b-2 border-met-primary bg-met-secondary' : ''}`}
										onClick={() => activeStep > index ? setActiveStep(index) : undefined}
									> {index < activeStep ? <Icons iconName={"succesStepIcon"} /> : (
										<RoundFab color={activeStep === index ? "met-secondary" : 'gray-300'} customClasses={`h-[32px] w-[32px] ${activeStep === index ? "text-met-primary" : "text-gray-700"}`}>
											{index + 1}
										</RoundFab>
									)
										}
										<>
											<Label fontSize="md" color={activeStep === index ? "met-primary" : ""} fontWeight={activeStep === index ? 'bold' : "normal"}>{step.label}</Label>
											{step.isSkippable ? <Checkbox checked={activeStep !== index && isActiveStepSkippable} onChangeCb={(value) => {
												if (value) {
													setIsActiveStepSkippable(value)
													if (isNavigationUrl) {
														navigate(parentPath + steps[index + 1].path);
													} else {
														setActiveStep((prev) => prev + 1);
													}
												}
											}} disabled={activeStep !== index} title="Don't have Insurance" /> : null}
										</>
									</div>
								</Link>
							) : (
								<div
									className={`flex flex-1 items-center p-3 pointer space-x-4 border-b ${activeStep === index ? 'border-b-2 border-met-primary bg-met-secondary' : ''}`}
									onClick={() => setActiveStep(index)}
								>
									{index < activeStep ? <Icons iconName={"succesStepIcon"} /> : (
										<RoundFab color={activeStep === index ? "met-secondary" : 'gray-300'} customClasses={`h-[32px] w-[32px] ${activeStep === index ? "text-met-primary" : "text-gray-700"}`}>
											{index + 1}
										</RoundFab>
									)
									}
									<Label fontSize="md" color={activeStep === index ? "met-primary" : ""} fontWeight={activeStep === index ? 'bold' : "normal"}>{step.label}</Label>
								</div>
							)}
						</React.Fragment>
					);
				})}
			</div>
			{steps.map((step, index) => {
				const Step = step.component

				if (!Step) {
					return null;
				} else {
					return (
						<React.Fragment key={'step-body-' + index}>
							{activeStep === index ? <div>
								<Step ref={formRef} />
							</div> : null}
						</React.Fragment>
					);
				}
			})}
			{steps.map((step, index) => {
				return (
					<React.Fragment key={'stepper-navigation-footer-' + index}>
						{activeStep === index ? (
							<footer className='mt-3'>
								<div className="flex justify-end">
									<div className="mr-2">
										<Button variant={BUTTON_VARIANTS.OUTLINED} onClickCb={step?.prevCb}>
											{step?.backButtonText}
										</Button>
									</div>
									
									<div>
										<Button
											variant={BUTTON_VARIANTS.CONTAINED}
											onClickCb={() => handleNextButtonClick(step, index)}
											type="submit"
										>
											{step?.submitButtonText}
										</Button>
									</div>
									
									{openABHACreateSuccessPopup ? (
										<SucessPopUp
											isOpen={openABHACreateSuccessPopup}
											successMesaage="ABHA Account created Successfully"
											onClickOk={() => {
												dispatch(setOpenABHACreateSuccessPopup(false));
												handleNextButtonClick(step, index);
											}}
										>
											<Icons iconName="successSvg"></Icons>
										</SucessPopUp>
									) : null}
								</div>
							</footer>
						) : null}
					</React.Fragment>
				);
			})}
			
		</div>
	);
};

export default StepperNavigation;
