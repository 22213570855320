import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';

const UpdateABHAAddressModal = ({ open, close, onConfirm }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.abhaAddress) {
            errors.abhaAddress = "ABHA Address is required.";
        } else if (!values.abhaAddress.endsWith("@sbx")) {
            errors.abhaAddress = "ABHA Address must end with '@sbx'.";
        } else {
            const abhaPrefix = values.abhaAddress.slice(0, -4);
    
            if (abhaPrefix.length < 8) {
                errors.abhaAddress = "ABHA Address must be at least 8 characters excluding '@sbx'.";
            } else if (abhaPrefix.length > 18) {
                errors.abhaAddress = "ABHA Address must not exceed 18 characters excluding '@sbx'.";
            } else {
                
                const dotCount = (abhaPrefix.match(/\./g) || []).length;
                const underscoreCount = (abhaPrefix.match(/_/g) || []).length;
    
                if (dotCount > 1) {
                    errors.abhaAddress = "ABHA Address can have at most 1 dot (.).";
                } else if (underscoreCount > 1) {
                    errors.abhaAddress = "ABHA Address can have at most 1 underscore (_).";
                }

                const abhaRegex = /^[a-zA-Z0-9._]+$/;
                if (!abhaRegex.test(abhaPrefix)) {
                    errors.abhaAddress =
                        "ABHA Address can only contain alphanumeric characters, dots (.), and underscores (_).";
                }

                if (/^[._]|[._]$/.test(abhaPrefix)) {
                    errors.abhaAddress =
                        "ABHA Address cannot start or end with a dot (.) or underscore (_).";
                }
            }
        }
    
        return errors;
    };
    

    return (
        <Formik
            initialValues={{
                abhaAddress: '',
            }}
            enableReinitialize
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const filteredAbhaAddress = values.abhaAddress.replace(/@sbx$/, ""); 
                onConfirm(filteredAbhaAddress);
                setSubmitting(false);
                close()
            }}
        >
            {({
                submitForm,
                isSubmitting,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                values,
            }) => (
                <Form>
                    <ModalComponent
                        open={open}
                        title="Update ABHA Address"
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                customBtnClass="text-met-primary"
                                onClickCb={submitForm}
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                        }
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[800px]"
                    >
                        <div className="p-4">
                            <div className="flex items-center gap-2 pt-6">
                                <div className="flex-1">
                                <Input
                                    label="Enter ABHA address"
                                    placeholder="Please enter ABHA address"
                                    isRequired
                                    name="abhaAddress"
                                    value={values.abhaAddress}
                                    onChangeCb={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("abhaAddress", value);
                                    }}
                                    onBlurCb={handleBlur}
                                />
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            )}
        </Formik>
    );
};

export default UpdateABHAAddressModal;
