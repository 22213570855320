import React, { useEffect, useState } from "react";
import { ABHA_HEALTH_RECORD_NAVS } from "./Constants";
import Heading from "../../../../components/common-components/heading/Heading";
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from "../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../components/common-components/button/Constants";
import Label from "../../../../components/common-components/label/Label";
import GenerateTokenPopup from "./GenerateTokenPopup";
import { useDispatch, useSelector } from "react-redux";
import { generateToken } from "../PatientDashboardSaga";
import { componentKey as PatientDashboardComponentKey, setIsTokenGenerated, setGeneratedTokenData } from "../PatientDashboardSlice";

const GenerateLinkToken = ({onConfirm}) => {
    const { patientData, isTokenGenerated } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [openGenerateTokenPopup, setOpenGenerateTokenPopup] = useState(false)
    const dispatch = useDispatch();

    const closePopup = () => {
        setOpenGenerateTokenPopup(false)
    }
    const clickNext = () => {
        setOpenGenerateTokenPopup(false)
        onConfirm(ABHA_HEALTH_RECORD_NAVS.CARE_CONTEXT_LINKING)
    }

    return (
        <>
            <div className="flex h-full flex-col gap-2 justify-between">
                <div className="flex flex-col gap-2">
                    <div>
                        <Button 
                            onClickCb={() => setOpenGenerateTokenPopup(true)} 
                            variant={BUTTON_VARIANTS.CONTAINED} 
                            customBtnClass={`px-4`}
                        >
                            Generate
                        </Button>
                    </div>
                    <Label fontSize="sm" fontWeight="bold" color="red-500">*Click on the Generate button to generate link token</Label>
                </div>                
            </div>
            {openGenerateTokenPopup && <GenerateTokenPopup close={closePopup} onConfirm={clickNext}/>}
        </>
    );
};

export default GenerateLinkToken;