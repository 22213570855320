import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { Form, Formik } from 'formik';
import Input from '../../../components/common-components/input/Input';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES } from './Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { VALIDATION_REGEX } from '../../../libs/constant';
import { getValidationSchema } from '../../../libs/formsUtils';
import {  abhaUpdateValidation, abhaUpdateOtpVerification } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import LimitReachedPopup from './LimitReachedPopup';
import { setStartTimer } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Label from '../../../components/common-components/label/Label';

const fields = [
	{
		fieldName: FORM_FIELDS_NAMES.OTP,
		isRequired: true,
		regexPattern: VALIDATION_REGEX.NUMBER_REGEX,
	}
];
const validationSchema = getValidationSchema(fields);

const UpdateABHAValidateOTPModal = ({ open, close, updateInputValue, updateName }) => {
	const dispatch = useDispatch();
	const { createPatientFromAadhaar, transactionIdAndAadhaar, startTimer } = useSelector((state) => state[componentKey]);
	const [ timer, setTimer ] = useState(60);
	const [resendDisabled, setResendDisabled] = useState(true);
	const [resendLimit, setResendLimit] = useState(2);
	const [showResendLimitPopup, setShowResendLimitPopup] = useState(false);
	const [showErrorText, setShowErrorText] = useState(true)

	useEffect(() => {
		let interval;
		if (open) {
			setTimer(60)
			interval = setInterval(() => {
				setTimer((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						setResendDisabled(false);
						dispatch(setStartTimer(false))
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [open, setResendDisabled, startTimer]);

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60).toString().padStart(2, '0');
		const seconds = (time % 60).toString().padStart(2, '0');
		return `${minutes}:${seconds}`;
	};

	

	const handleResendOTP = async () => {
		if(resendLimit > 0){
			setResendLimit(prevState => prevState - 1);
			setResendDisabled(true)
			try {
				const payload = {
                    loginId: updateInputValue,
                    xtoken: createPatientFromAadhaar?.patient?.xtoken,
                    loginHint:updateName
                }
                dispatch(abhaUpdateValidation(payload));
				
			} catch (error) {
				console.error("Failed to resend OTP:", error);
			}
			setTimer(60);
		}else{
			setShowResendLimitPopup(true);
		}
		
	}
	const validateValues = (values) => {
		if(values.otp.length !== 6) return false;
		return true;
	}


	return (
		<>
			<Formik
				initialValues={
					{
						[FORM_FIELDS_NAMES.OTP]: '',
					}
				}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => {
					if(!validateValues(values)){
						setShowErrorText({
							show: true,
							message: `Invalid OTP`
						})
						return;
					}
					let payload = {
						...values,
                        txnId:transactionIdAndAadhaar.transactionId,
                        xtoken: createPatientFromAadhaar?.patient?.xtoken,
                        loginHint:updateName,
                        loginId: updateInputValue

					}
					dispatch(abhaUpdateOtpVerification({otpVerificationData:payload}));
					close()
				}}
			>
				{({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div>
								<ModalComponent
									open={open}
									close={close}
									title={'Validate OTP'}
									footerButton={
										<div className={`${showErrorText?.show ? "w-full flex items-center justify-between": ""}`}>
											{showErrorText?.show && <Label asteriskPosition="start" color="red-500" fontSize="sm">* {showErrorText?.message}</Label>}
											<div className='flex gap-2.5'>
												<Button
													type="button"
													variant={BUTTON_VARIANTS.CONTAINED}
													customBtnClass={`text-met-primary ${resendDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
													onClickCb = {() => handleResendOTP()}
													disabled={resendDisabled}
												>
													Resend OTP
												</Button>
												<Button
													type="submit"
													variant={BUTTON_VARIANTS.CONTAINED}
													customBtnClass="text-met-primary"
												>
													Submit
												</Button>
											</div>	
										</div>
										
									}
									customClasses="w-[800px]"
								>
									<div className="p-2">
										<div className="flex column-gap-10px items-center pt-6">
											<div className="flex-1">
												<div>
													<Input
														label="OTP"
														placeholder="Please enter 6 digits OTP"
														isRequired={true}
														name={FORM_FIELDS_NAMES.OTP}
														value={values[FORM_FIELDS_NAMES.OTP]}
														onChangeCb={(e) => {
															setShowErrorText(null)
															const numericValue = e.target.value.replace(/[^0-9]/g, '');
															if (numericValue.length <= 6) {
																setFieldValue(
																	FORM_FIELDS_NAMES.OTP,
																	numericValue
																);
															}
															
														}}
														onBlurCb={handleBlur}
													/>
												</div>
												{startTimer && (
													<div className="text-left pt-2 text-sm">
														Resend in {formatTime(timer)}
													</div>
												)}
											</div>
										</div>
									</div>
								</ModalComponent>
							</div>
						</Form>
					);
				}}
			</Formik>
			{showResendLimitPopup ? 
				<LimitReachedPopup
					open={showResendLimitPopup}
					close={() =>  {
						setShowResendLimitPopup(false);
						close()
					}}
				/> : null
			}
		</>
	);
};

export default UpdateABHAValidateOTPModal;
