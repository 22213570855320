import { v4 as uuidv4 } from 'uuid';

class HealthRecordHelper {
    static getStructurePatientData(patientData) {
        return {
            resourceType: "Patient",
            id: uuidv4(),
            name: [
                {
                text: `${patientData.firstName} ${
                    patientData.middleName ? patientData.middleName + " " : ""
                }${patientData.lastName}`,
                },
            ],
            telecom: [
                {
                system: "phone",
                value: patientData.mobileNumber,
                },
            ],
            gender: patientData.gender,
            birthDate: patientData.dob.split("T")[0],
            address: patientData.Address.map((addr) => ({
                use: 'home',
                text: `${addr.addressLine1} ${addr.addressLine2} ${addr.country}`,
            })),
        };
    }

    static getStructuredDiagnosticLabReport(report) {
        return {
            resourceType: report.resourceType,
            id: uuidv4(),
            status: report.status,
            specimen: report?.specimen?.map((item) => ({
                resourceType: "Specimen",
                id: uuidv4(),
                type: item.type,
                recievedTime: item.recievedTime,
            })),
            result: report.result?.map((item) => ({
                resourceType: "Observation",
                id: uuidv4(),
                status: item.data.status,
                code: item.data.code,
                value: item.data.value,
            })),
            conclusion: report.conclusion,
        };
    }

    static getStructuredMedicationRequestData(report) {
        return {
            id: uuidv4(),
            ...report,
            reasonReference: report.reasonReference?.map((item) => {
                return{
                    id: uuidv4(),
                    ...item.data
                }
            })

        };
    }
}

export default HealthRecordHelper;
