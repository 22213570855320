import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { DOCUMENT_STATUS_OPTIONS } from "../Constants";
import Upload from "../../../../../components/common-components/upload/Upload";
import Icons from "../../../../../components/icons/Icons";
import General from "../../../../../libs/utility/General";
import Label from "../../../../../components/common-components/label/Label";

const DocumentReference = ({ open, close, onConfirm }) => {
    const [fileName, setFileName] = useState("");
    const [uploadKey, setUploadKey] = useState(Date.now());
    const [uploadError, setUploadError] = useState("");

    const validate = (values) => {
        const errors = {};
        if (!values.documentStatus || values.documentStatus.value === "Select Status") {
            errors.documentStatus = "Please select a document status.";
        }
        if (!values.data) {
            setUploadError("Please upload atleast 1 file.");
        }
        return errors;
    };

    return (
        <>
            <Formik
                initialValues={{
                    documentStatus: { label: "Select Status", value: "Select Status" },
                    data: "",
                }}
                validate={validate}
                onSubmit={(values) => {
                    const documentReferenceData = {
                        resourceType: "DocumentReference",
                        status: values.documentStatus.value,
                        content: [
                            {
                                attachment: {
                                    contentType: values.contentType,
                                    data: values.data,
                                },
                            },
                        ],
                    };
                    onConfirm((prev) => [...prev, { type: `Document Reference ${prev.length + 1}`, data: documentReferenceData }]);
                    close();
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <ModalComponent
                        open={open}
                        title={"Add Document Reference"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={() => close()}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                    >
                        <div className="grid grid-cols-1 gap-6">
                            <SelectDropdown
                                label="Document Status"
                                name="documentStatus"
                                customClasses="w-full"
                                isRequired={true}
                                value={values.documentStatus}
                                options={[{ label: "Select Status", value: "Select Status" }, ...DOCUMENT_STATUS_OPTIONS]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("documentStatus", selectedOption)
                                }
                            />
                            <div className="flex flex-col gap-2  border border-gray-300 rounded-lg p-4 bg-gray-100">
                                <Upload
                                    key={uploadKey}
                                    icon={<Icons height="40px" width="40px" iconName="fileUpload" />}
                                    title="Drag & Drop files"
                                    content="Or"
                                    subTitle="Browse Files"
                                    customClass="w-[150px] h-[150px]"
                                    name="data"
                                    value={values.data}
                                    onChangeCb={async (file) => {
                                        if (file) {
                                            const mimeType = file.type || "";
                                            const base64String = await General.fileToBase64(file);
                                            const base64 = `data:${mimeType};base64,${base64String}`;
                                            setFieldValue("data", base64);
                                            setFieldValue("contentType", mimeType);
                                            setFileName(file.name);
                                            setUploadError("")
                                        }
                                    }}
                                />
                                {uploadError && (
                                    <Label fontSize="sm" fontWeight="bold" color="red-500">
                                        {uploadError}
                                    </Label>
                                )}
                                {fileName && (
                                    <div className="flex items-center justify-between bg-gray-200 border border-gray-300 rounded-lg px-4 py-2">
                                        <span className="truncate">{fileName}</span>
                                        <div className=" hover:cursor-pointer" 
                                            onClick={() => {
                                                setFieldValue("data", "");
                                                setFieldValue("contentType", "");
                                                setFileName("");
                                                setUploadKey(Date.now());
                                            }}>
                                            <Icons iconName={"closeIcon"} color={"red"}/>
                                        </div>
                                        
                                    </div>
                                )}
                            </div>
                        </div>
                    </ModalComponent>
                )}
            </Formik>
        </>
    );
};

export default DocumentReference;
