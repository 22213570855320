import React from 'react';

function InfoIcon({ color = "rgba(26,26,26,0.5)", size = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-.75-12h1.5v1.5h-1.5zm0 3h1.5v7.5h-1.5z" />
    </svg>
  );
}

export default InfoIcon;
