import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  ABHA_HEALTH_RECORD_NAVS, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import DiagnosticLabReport from "./diagnosticLabReport";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { createDiagnosticBundle } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import Modal from "../Modal";
import SuccessSvg from "../../../../../components/icons/vectors/SuccessSvg";

const DiagnosticRecord = ({onConfirm}) => {
  const { patientData, diagnosticBundle } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [reportsList, setReportsList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
      errors.compositionStatus = "Please select composition status.";
    }
    return errors;
  };

  useEffect(() => {
    setReportsError("")
  },[reportsList])

  useEffect(() => {
    if(diagnosticBundle){
      setOpenSuccessPopup(true);
    }
  },[diagnosticBundle])
  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values.compositionStatus.value,
        subject: {
          resourceType: "Patient",
          id: uuidv4(),
          name: [
            {
              text: `${patientData.firstName} ${
                patientData.middleName ? patientData.middleName + " " : ""
              }${patientData.lastName}`,
            },
          ],
          telecom: [
            {
              system: "phone",
              value: patientData.mobileNumber,
            },
          ],
          gender: patientData.gender,
          birthDate: patientData.dob.split("T")[0],
          address: patientData.Address.map((addr) => {
              return{
                  use:'home',
                  text: `${addr.addressLine1} ${addr.addressLine2} ${addr.country}`,
              }
          })
        },
        entry: reportsList?.map((report) => ({
          resourceType: report.labReport.resourceType,
          id: uuidv4(),
          status: report.labReport.status,
          specimen: report?.labReport.specimen?.map((item) => ({
            resourceType: "Specimen",
            id: uuidv4(),
            type: item.type,
            recievedTime: item.recievedTime,
          })),
          result: report.labReport.result?.map((item) => ({
            resourceType: "Observation",
            id: uuidv4(),
            status: item.data.status,
            code: item.data.code,
            value: item.data.value,
          })),
          conclusion: report.labReport.conclusion,
        })),
      };
      return payload;
  };

  return (
      <>
          <Formik
              initialValues={{
                  compositionStatus:{ label: "Select Status", value: "Select Status" },
                  recordType: { label: "Select Record Type", value: "Select Record Type" },
              }}
              validate={validate}
              onSubmit={(values) => {
                if(reportsList.length === 0 || reportsList.length > 2) {
                  setReportsError("Atleast 1 and max 2 reports are required.");
                  return;
              }
                const payload = structurePayloadWithAllData(values);
                dispatch(createDiagnosticBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="space-y-4 h-full w-full flex flex-col justify-between">
                      <div className="flex h-full flex-col gap-2">
                          <SelectDropdown
                              label="Composition Status"
                              name="compositionStatus"
                              customClasses="w-1/2"
                              value={values.compositionStatus}
                              options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                              onChangeCb={(selectedOption) =>
                                  setFieldValue("compositionStatus", selectedOption)
                              }
                          />
                          <div className="flex flex-col gap-2">
                            <ItemManager
                                title={<>Report entry <span className="text-red-500">*</span></>} 
                                items={reportsList}
                                setItems={setReportsList}
                                setIsPopupOpen={setIsPopupOpen}
                                customHeight="96"
                            />
                            {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                          </div>
                          
                      </div>
                      <div className="flex justify-end">
                          <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                              Submit
                          </Button>
                      </div>
                  </form>
              )}
          </Formik>
          {isPopupOpen && <DiagnosticLabReport onConfirm={setReportsList} close={() => setIsPopupOpen(false)}/>}
          <Modal
              title=""
              isOpen={openSuccessPopup}
              onConfirm={() => {
                setOpenSuccessPopup(false)
                onConfirm(ABHA_HEALTH_RECORD_NAVS.DATA_PUSH_TO_ABDM)
              }}
              submitText="Okay"
          >
            <div className="flex flex-col gap-4 justify-center items-center p-2">
                <SuccessSvg/>
                <h1 className="font-bold text-lg">Diagnostic Bundle created successfully</h1>
            </div>
          </Modal>
      </>
  );
};

export default DiagnosticRecord;
