import store from '../../../store/store';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { componentKey, setPatientData, setIsTokenGenerated, setIsABDMAcknowledged, setDiagnosticBundle, setIsDataPushedToABDM, setIsHINotified, setPrescriptionBundle, setSelectedBundle, setDischargeSummaryBundle } from './PatientDashboardSlice';
import PatientDataService from '../../../services/PatientDataServices';
import { addNotifications } from '../../../components/common-components/toaster/ToasterSlice';
import { TOASTER_VARIANT } from '../../../components/common-components/toaster/MetToaster';

export const {
    getPatientDetails, generateToken, careContextLinking, hipNotify, acknowledgeABDM, createDiagnosticBundle, dataPushToABDM, healthInformationNotify, createDischargeSummaryBundle, createHealthDocumentBundle, createPrescriptionBundle
} = {
    getPatientDetails: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/PATIENTDETAILS',
            payload,
        };
    },
    generateToken: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/GENERATE_TOKEN',
            payload,
        }
    },
    careContextLinking: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CARE_CONTEXT_LINKING',
            payload,
        }
    },
    hipNotify: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/HIP_NOTIFY',
            payload,
        }
    },
    acknowledgeABDM: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/ACKNOWLEDGE_ABDM',
            payload,
        }
    },
    dataPushToABDM: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/DATA_PUSH_ABDM',
            payload,
        }
    },
    healthInformationNotify: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/HEALTH_INFORMATION_NOTIFY',
            payload,
        }
    },
    createDiagnosticBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_DIAGNOSTIC_BUNDLE',
            payload,
        }
    },
    createDischargeSummaryBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_DISCHARGE_SUMMARY_BUNDLE',
            payload,
        }
    },
    createHealthDocumentBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_HEALTH_DOCUMENT_BUNDLE',
            payload,
        }
    },
    createPrescriptionBundle: (payload) => {
        return {
            type: 'PATIENTDASHBOARD/CREATE_PRESCRPTION_BUNDLE',
            payload,
        }
    }
};

function* getPatientDetailsAsync(action) {
    try {
        const { patientId = '', page = '1', limit = '10' } = action.payload;
        const params = {
            page: page,
            limit: limit,
        };

        const response = yield PatientDataService.getPatientDetails(
            patientId,
            params
        );

        const { message, status, data } = response?.data || {};
        if (status) {
            yield put(setPatientData(data));
        }
    } catch (error) {
        console.log('err: ', error);
    }
}

function* generateTokenAsync(action) {
    try {
        const {id} = action.payload;
        const response = yield PatientDataService.generateToken(id);
        if(response.status !== 200){
            yield put(addNotifications({ message: "Something went wrong" , variant: TOASTER_VARIANT.ERROR }));
        }
    } catch (error) {
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* careContextLinkingAsync(action) {
    try {
        const response = yield PatientDataService.careContextLinking(action.payload);
        if(response.status !== 201){
            yield put(addNotifications({ message: "Something went wrong" , variant: TOASTER_VARIANT.ERROR }));
        }
    } catch(error) {
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* hipNotifyAsync(action) {
    try {
        const data = action.payload
        const response = yield PatientDataService.hipNotify(data);
        if(response.status !== 201 ){
            yield put(addNotifications({ message: "Something went wrong" , variant: TOASTER_VARIANT.ERROR }));
        }
    } catch(error) {
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* acknowledgeABDMAsync(action) {
    try{
        const payload = action.payload;
        const response =  yield PatientDataService.acknowledgeABDM(payload);
        if(response.status === 201){
            yield put(setIsABDMAcknowledged(true));
        }
    } catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createDiagnosticBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createDiagnosticBundle(payload);
        if(response.status === 201){
            yield put(setDiagnosticBundle(response.data));
            yield put(setSelectedBundle(response.data));
            yield put(addNotifications({ message: "Diagnostic report bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* dataPushToABDMAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.dataPushToABDM(payload);
        if(response.status === 201){
            yield put(setIsDataPushedToABDM(true))
            yield put(addNotifications({ message: "Bundle data is pushed to ABDM successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}
function* healthInformationNotifyAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.healthInformationNotify(payload);
        if(response.status === 201){
            yield put(setIsHINotified(true));
            yield put(addNotifications({ message: "Health Inoformation notified successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
        yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
        console.log('err:', error);
    }
}

function* createPrescriptionBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createPrescriptionBundle(payload);
        if(response.status === 201){
            yield put(setPrescriptionBundle(response.data));
            yield put(setSelectedBundle(response.data));
            yield put(addNotifications({ message: "Prescription report bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createDischargeSummaryBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createDischargeSummaryBundle(payload);
        if(response.status === 201){
            yield put(setDischargeSummaryBundle(response.data));
            yield put(setSelectedBundle(response.data));
            yield put(addNotifications({ message: "Discharge summary bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* createHealthDocumentBundleAsync(action) {
    try{
        const payload = action.payload;
        const response = yield PatientDataService.createHealthDocumentBundle(payload);
        if(response.status === 201){
            yield put(setSelectedBundle(response.data));
            yield put(addNotifications({ message: "Health Document bundle created successfully" , variant: TOASTER_VARIANT.SUCCESS }));
        }

    }catch(error){
        const errorMessage =
            error?.response?.data?.message ||
            "Something went wrong!"; 
		yield put(addNotifications({ message: errorMessage , variant: TOASTER_VARIANT.ERROR }));
		console.log('err:', error);
    }
}

function* rootSaga() {
    yield all([
        takeLatest(getPatientDetails().type, getPatientDetailsAsync),
        takeLatest(generateToken().type, generateTokenAsync),
        takeLatest(careContextLinking().type, careContextLinkingAsync),
        takeLatest(hipNotify().type, hipNotifyAsync),
        takeLatest(acknowledgeABDM().type, acknowledgeABDMAsync),
        takeLatest(createDiagnosticBundle().type, createDiagnosticBundleAsync),
        takeLatest(dataPushToABDM().type, dataPushToABDMAsync),
        takeLatest(healthInformationNotify().type, healthInformationNotifyAsync),
        takeLatest(createDischargeSummaryBundle().type, createDischargeSummaryBundleAsync),
        takeLatest(createHealthDocumentBundle().type, createHealthDocumentBundleAsync),
        takeLatest(createPrescriptionBundle().type, createPrescriptionBundleAsync),
    ]);
}

store.sagaManager.addSaga(componentKey, rootSaga);
