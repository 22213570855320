import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setClearOtpOrMobile, setIsCreateABHA, setOpenValidateOtpModal } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { Form, Formik } from 'formik';
import Input from '../../../components/common-components/input/Input';
import Icons from '../../../components/icons/Icons';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES } from './Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { VALIDATION_REGEX } from '../../../libs/constant';
import { getValidationSchema } from '../../../libs/formsUtils';
import { otpVerification } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { checkExistingAadhaar, getExistingABHADetails } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import LimitReachedPopup from './LimitReachedPopup';
import { setStartTimer } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Label from '../../../components/common-components/label/Label';

// const fields = [
// 	{
// 		fieldName: FORM_FIELDS_NAMES.OTP,
// 		isRequired: true,
// 		regexPattern: VALIDATION_REGEX.NUMBER_REGEX,
// 	}
// ];
// const validationSchema = getValidationSchema(fields);

const ValidateOTPModal = ({ open, close, getVia, otpOption, searchValue, modalType }) => {
	const dispatch = useDispatch();
	const { transactionIdAndAadhaar, createPatientData, startTimer, clearOtpOrMobile, isCreateABHA } = useSelector((state) => state[componentKey]);
	const [ timer, setTimer ] = useState(60);
	const [resendDisabled, setResendDisabled] = useState(true);
	const [resendLimit, setResendLimit] = useState(2);
	const [showResendLimitPopup, setShowResendLimitPopup] = useState(false);
	const [showErrorText, setShowErrorText] = useState({show: false})
	const [otp, setOtp] = useState("");
	const [mobile, setMobile] = useState("");

	useEffect(() => {
		let interval;
		if (open) {
			setTimer(60)
			interval = setInterval(() => {
				setTimer((prev) => {
					if (prev <= 1) {
						clearInterval(interval);
						setResendDisabled(false);
						dispatch(setStartTimer(false))
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
		}
		return () => clearInterval(interval);
	}, [open, setResendDisabled, startTimer]);

	const formatTime = (time) => {
		const minutes = Math.floor(time / 60).toString().padStart(2, '0');
		const seconds = (time % 60).toString().padStart(2, '0');
		return `${minutes}:${seconds}`;
	};

	
	const handleResendOTP = async (setFieldValue) => {
		dispatch(setClearOtpOrMobile(false))
		if(resendLimit > 0){
			setResendLimit(prevState => prevState - 1);
			setResendDisabled(true)
			try {
				if(modalType === "getABHADetails"){
                    if (!getVia || !searchValue) {
                        console.error("Invalid input or selection");
                        return;
                    }
                    const payload = {
						key: getVia,
						[getVia]: searchValue,
						...(otpOption === "mobileLink" || otpOption === "aadharLink"
							? { sentTo: otpOption }
							: {})
					};
					await dispatch(getExistingABHADetails({getExistingABHADetailsData:payload}));
				}else {
					await dispatch(checkExistingAadhaar(Number(transactionIdAndAadhaar.aadhar)));
				}
				
			} catch (error) {
				console.error("Failed to resend OTP:", error);
			}
			setTimer(60);
			setOtp("");
			setMobile("");
		}else{
			setShowResendLimitPopup(true);
		}
		
	}
	const validateValues = (values) => {
		if(values.otp.length !== 6 ) return {status:false, message: "Invalid OTP"};
		if(modalType !== "getABHADetails" && (!/^[6-9]\d{9}$/.test(values.mobileNumber) || values.mobileNumber.length !== 10)) return {status:false, message:"Mobile number must be 10 digits and start with 6-9"};
		return {status:true};
	}

	useEffect(() => {
		if(clearOtpOrMobile){
			setOtp("");
			setMobile("");
		}
		return () => {
			dispatch(setIsCreateABHA(false));
		}
	},[clearOtpOrMobile])
	
	let payloadData = modalType === "getABHADetails" ? {
		txnId:transactionIdAndAadhaar.transactionId,
		key: getVia,
		providerId: createPatientData.providerId,
		providerLocationId : createPatientData.providerLocationId,
		registrationDate: createPatientData.registrationDate,
		// ...((getVia === "abhaAddress" || getVia === "abhaNumber") ? {sendTo:otpOption} : {}),
	} : {
		txnId:transactionIdAndAadhaar.transactionId,
		...((isCreateABHA) ? {
			aadhar : Number(transactionIdAndAadhaar.aadhar),
			isCreate:"true",
			providerId: createPatientData.providerId,
			providerLocationId : createPatientData.providerLocationId,
			registrationDate: createPatientData.registrationDate,
		}:{})
	}

	return (
		<>
			<Formik
				initialValues={
					{
						[FORM_FIELDS_NAMES.MOBILE_NUMBER]: '',
						[FORM_FIELDS_NAMES.OTP]: '',
					}
				}
				//validationSchema={validationSchema}
				enableReinitialize
				onSubmit={ () => {
					dispatch(setClearOtpOrMobile(false))
					const values = { otp, mobileNumber: mobile };
					const fieldsValidation = validateValues(values)
					try{
						if(!fieldsValidation.status){
							setShowErrorText({
								show: true,
								message: fieldsValidation.message
							})
							return;
						} else {
							setShowErrorText({show:false})
						}
						let payload = {
							otp,
							...(mobile !=="" ? {mobileNumber:mobile} : {}),
							...payloadData
	
						}
						dispatch(otpVerification({otpVerificationData:payload}));

					} catch(error) {
						console.log("Error", error);
					}
					
					
				}}
			>
				{({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div>
								<ModalComponent
									open={open}
									close={close}
									title={'Validate OTP'}
									footerButton={
										<div className={`${showErrorText?.show ? "w-full flex items-center justify-between": ""}`}>
											{showErrorText?.show && <Label asteriskPosition="start" color="red-500" fontSize="sm">* {showErrorText?.message}</Label>}
											<div className='flex gap-2.5'>
												<Button
													type="button"
													variant={BUTTON_VARIANTS.CONTAINED}
													customBtnClass={`text-met-primary ${resendDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
													onClickCb = {() => handleResendOTP()}
													disabled={resendDisabled}
												>
													Resend OTP
												</Button>
												<Button
													type="submit"
													variant={BUTTON_VARIANTS.CONTAINED}
													customBtnClass="text-met-primary"
												>
													Submit
												</Button>
											</div>	
										</div>
										
									}
									customClasses="w-[800px]"
								>
									<div className="p-2">
										<div className="flex column-gap-10px items-center pt-6">
											<div className="flex-1">
												<div>
													<Input
														label="OTP"
														placeholder="Please enter 6 digits OTP"
														isRequired={true}
														name={"otp"}
														value={otp}
														onChangeCb={(e) => {
															setShowErrorText(null)
															const numericValue = e.target.value.replace(/[^0-9]/g, '');
															if (numericValue.length <= 6) {
																setOtp(numericValue);
															}
															
														}}
														onBlurCb={handleBlur}
													/>
												</div>
												{modalType !== "getABHADetails" && 
												<div>
													<Input
														label="Mobile Number"
														placeholder="Please enter 10 digits Mobile Number"
														isRequired={true}
														name={FORM_FIELDS_NAMES.MOBILE_NUMBER}
														value={mobile}
														onChangeCb={(e) => {
															const numericValue = e.target.value.replace(/[^0-9]/g, '');
															if (numericValue.length <= 10) {
																setMobile(numericValue)
															}
														}}
														onBlurCb={handleBlur}
														maxLength={10}
													/>
												</div> }
												{startTimer && (
													<div className="text-left pt-2 text-sm">
														Resend in {formatTime(timer)}
													</div>
												)}
											</div>
										</div>
									</div>
								</ModalComponent>
							</div>
						</Form>
					);
				}}
			</Formik>
			{showResendLimitPopup ? 
				<LimitReachedPopup
					open={showResendLimitPopup}
					close={() =>  {
						setShowResendLimitPopup(false);
						close()
					}}
				/> : null
			}
		</>
	);
};

export default ValidateOTPModal;
