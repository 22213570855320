import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import Input from '../../../components/common-components/input/Input';
import General from '../../../libs/utility/General';
import { getValidationSchema } from '../../../libs/formsUtils';
import { VALIDATION_REGEX } from '../../../libs/constant';
import { FORM_FIELDS_NAMES } from './Constants';
import { abhaIntAadhaarValidation, checkExistingAadhaar } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import ValidateOTPModal from './ValidateOTPModal';
import { componentKey, setOpenValidateOtpModal } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import ConsentFormABHA from './consentFormABHA';

const fields = [
	{
		fieldName: FORM_FIELDS_NAMES.AADHAR_NUMBER,
		isRequired: true,
		regexPattern: VALIDATION_REGEX.AADHAR_REGEX,
	},
];
const validationSchema = getValidationSchema(fields);

const ImportFromAadhaar = ({ open, close }) => {
	const {openValidateOtpModal } = useSelector((state) => state[componentKey])
	const [openConsentForm, setOpenConsentForm] = useState(false);
	const dispatch = useDispatch();
	return (
		<>
			<Formik
				initialValues={{
					[FORM_FIELDS_NAMES.AADHAR_NUMBER]: '',
				}}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={async (values) => {
					const aadhar = values[FORM_FIELDS_NAMES.AADHAR_NUMBER].replace(/-/g, '');
					dispatch(checkExistingAadhaar(Number(aadhar)));
					setOpenConsentForm(false);
					close();
				}}
			>	
				{({
					submitForm,
					isSubmitting,
					handleReset,
					values,
					handleSubmit,
					setFieldValue,
					handleBlur,
					...props
				}) => {
					return (
						<>
							<Form>
								<div>
									<ModalComponent
										open={open}
										title={'Import Data From Aadhaar'}
										footerButton={
											<Button
												//type="submit"
												variant={BUTTON_VARIANTS.CONTAINED}
												customBtnClass="text-met-primary"
												onClickCb={() => {
													
													if(values[FORM_FIELDS_NAMES.AADHAR_NUMBER].replace(/-/g, '').length === 12){
														setOpenConsentForm(true)
													}
												}}
											>
												Generate OTP
											</Button>
										}
										icon={<Icons iconName="closeIcon" />}
										close={close}
										customClasses="w-[800px]"
									>
										<div className="p-2">
											<div className="flex column-gap-10px items-center pt-6">
												<div className="flex-1">
													<div>
														<Input
															label="Aadhar Number"
															placeholder="Please enter Aadhar Number"
															isRequired={true}
															name={FORM_FIELDS_NAMES.AADHAR_NUMBER}
															value={values[FORM_FIELDS_NAMES.AADHAR_NUMBER]}
															onChangeCb={(e) => {
																const formattedValue = General.formateAadhar(
																	e.target.value
																);
																setFieldValue(
																	FORM_FIELDS_NAMES.AADHAR_NUMBER,
																	formattedValue
																);	
															}}
															onBlurCb={handleBlur}
														/>
													</div>
												</div>
											</div>
										</div>
									</ModalComponent>
								</div>
							</Form>
							{openConsentForm && 
								<ConsentFormABHA 
									close={() => setOpenConsentForm(false)}
									onSubmit={handleSubmit}
								/>
							}
						</>
					);
				}}
			</Formik>
			
		</>
	);
};

export default ImportFromAadhaar;
