import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../components/icons/Icons';
import General from '../../../libs/utility/General';
import { FORM_FIELDS_NAMES } from './Constants';
import { abhaProfilePictureUpdate } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { componentKey, setABHAProfilePicture } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import Upload from '../../../components/common-components/upload/Upload';
import { MET_COLORS } from '../../../libs/constant';

const UploadProfilePictureModal = ({ open, close }) => {
    const { createPatientFromAadhaar, patientDetailsReviewLocation } = useSelector(state => state[componentKey]);
    const [profilePicture, setProfilePicture] = useState(null);
    const [fileName, setFileName] = useState('');
    const [uploadKey, setUploadKey] = useState(Date.now());
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const handleFileUpload = async (file) => {
        if (file) {
            const fileSizeInKB = file.size / 1024;
            const fileType = file.type.toLowerCase();
    
            const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
            
            if (!allowedFileTypes.includes(fileType)) {
                setUploadKey(Date.now());
                setError('Only JPG, JPEG, or PNG formats are allowed.');
                return;
            }

            if (fileSizeInKB > 5) {
                setUploadKey(Date.now());
                setError('File size must be 5KB or less.');
                return;
            }
    
            try {
                const mimeType = file.type || '';
                const base64String = await General.fileToBase64(file);
                const base64 = `data:${mimeType};base64,${base64String}`;
                const encodeBase64 = base64?.split(",")[1];
                setProfilePicture({ encodeBase64 });
                setFileName(file.name);
                setError('');
            } catch (err) {
                setError('Error processing the file. Please try again.');
            }
        }
    };
    

    const handleRemoveFile = () => {
        setProfilePicture(null);
        setFileName('');
        setError('');
        setUploadKey(Date.now());
    };

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={() => {
                const payload = {
                    profilePhoto:profilePicture.encodeBase64,
                    uuid: createPatientFromAadhaar?.patient?.uuid,
                    xtoken: createPatientFromAadhaar?.patient?.xtoken
                }
                if(patientDetailsReviewLocation === "abhaInformationTab"){
                    dispatch(setABHAProfilePicture(profilePicture))
                } else {
                    dispatch(abhaProfilePictureUpdate({abhaProfilePictureUpdateData:payload}))
                }
                close()
            }}
        >
            {({
                handleSubmit,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <ModalComponent
                        open={open}
                        title="Upload Profile Picture"
                        footerButton={
                            <Button
                                type="submit"
                                variant={BUTTON_VARIANTS.CONTAINED}
                                customBtnClass="text-met-primary"
                                disabled={!profilePicture}
                            >
                                Update Profile Picture
                            </Button>
                        }
                        icon={<Icons iconName="closeIcon" />}
                        close={close}
                        customClasses="w-[800px]"
                    >
                        <div className="p-2">
                            <div className="flex flex-col gap-8 pt-6">
                                <div className="flex flex-col items-center gap-2 text-center border border-gray-300 rounded-lg p-4 bg-gray-100">
                                    <Icons iconName="profileExample" className="w-12 h-12 text-met-primary" />
                                    <p className="text-sm text-gray-700">
                                        Upload a recent, clear photo with the face well-lit and fully visible.
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Accepted formats: JPG, JPEG, PNG. Maximum file size: 5KB.
                                    </p>
                                </div>

                                <div>
                                    <Upload
                                        key={uploadKey}
                                        customClass="w-[130px] h-[130px]"
                                        subTitle="Upload"
                                        icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
                                        onChangeCb={handleFileUpload}
                                    />
                                </div>

                                {error && (
                                    <div className="text-red-500 text-sm mt-2">{error}</div>
                                )}

                                {profilePicture && (
                                    <div className="flex gap-4 px-4 py-2 border border-gray-400 shadow-md shadow-gray-400 rounded-lg items-center justify-between">
                                        <div className="truncate">{fileName}</div>
                                        <div className=" hover:cursor-pointer" onClick={handleRemoveFile}>
                                            <Icons iconName={"closeIcon"} color={"red"}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </ModalComponent>
                </Form>
            )}
        </Formik>
    );
};

export default UploadProfilePictureModal;


