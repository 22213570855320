import React, { useState } from "react";
import { Formik } from "formik";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import Input from "../../../../../components/common-components/input/Input";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ModalComponent from "../../../../../components/common-components/modal/ModalComponent";
import { CARE_PLAN_STATUS, CARE_PLAN_INTENT } from "../Constants";
import ItemManager from "../ItemManager";
import { v4 as uuidv4 } from "uuid";
import Goal from "./Goal";

const CarePlan = ({ open, close, onConfirm }) => {
    const [goalList, setGoalList] = useState([]);
    const [isGoalPopupOpen, setIsGoalPopupOpen] = useState(false);
    const validate = (values) => {
        const errors = {};
        if (!values.carePlanStatus || values.carePlanStatus.value === "Select Status") {
            errors.carePlanStatus = "Please select a care plan status.";
        }
        if (!values.carePlanIntent || values.carePlanIntent.value === "Select Intent") {
            errors.carePlanIntent = "Please select a care plan intent.";
        }
        return errors;
    };

    return (
        <>
            <Formik
                initialValues={{
                    carePlanStatus: { label: "Select Status", value: "Select Status" },
                    carePlanIntent: { label: "Select Intent", value: "Select Intent" },
                    carePlanCategory: "",
                }}
                validate={validate}
                onSubmit={(values) => {
                    const carePlanData = {
                        resourceType: "CarePlan",
                        status: values.carePlanStatus.value,
                        intent: values.carePlanIntent.value,
                        category: {
                            text: values.carePlanCategory,
                        },
                        goal: goalList.map((item) => item.data),
                    };
                    onConfirm((prev) => [...prev, { type: `Care Plan Report ${prev.length + 1}`, data: carePlanData }]);
                    close();
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => (
                    <ModalComponent
                        open={open}
                        title={"Add Care Plan"}
                        footerButton={
                            <Button
                                variant={BUTTON_VARIANTS.CONTAINED}
                                onClickCb={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        }
                        close={() => close()}
                        customClasses="w-full sm:w-[90%] md:w-[80%] lg:w-[75%]"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <SelectDropdown
                                label="Care Plan Status"
                                name="carePlanStatus"
                                customClasses="w-full"
                                isRequired={true}
                                value={values.carePlanStatus}
                                options={[{ label: "Select Status", value: "Select Status" }, ...CARE_PLAN_STATUS]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("carePlanStatus", selectedOption)
                                }
                            />
                            <SelectDropdown
                                label="Care Plan Intent"
                                name="carePlanIntent"
                                customClasses="w-full"
                                isRequired={true}
                                value={values.carePlanIntent}
                                options={[{ label: "Select Intent", value: "Select Intent" }, ...CARE_PLAN_INTENT]}
                                onChangeCb={(selectedOption) =>
                                    setFieldValue("carePlanIntent", selectedOption)
                                }
                            />
                            <Input
                                label="Category"
                                placeholder="Enter care plan category"
                                name="carePlanCategory"
                                value={values.carePlanCategory}
                                onChangeCb={(e) => setFieldValue("carePlanCategory", e.target.value)}
                            />
                            <ItemManager
                                key={`goal${uuidv4()}`}
                                title={ "Goals"}
                                items={goalList}
                                setItems={setGoalList}
                                setIsPopupOpen={setIsGoalPopupOpen}
                                customHeight="52"
                                customClass={"col-span-2"}
                            />
                        </div>
                    </ModalComponent>
                )}
            </Formik>
            {isGoalPopupOpen && 
                <Goal
                    open={isGoalPopupOpen}
                    onConfirm={setGoalList}
                    close={() => setIsGoalPopupOpen(false)}
                />
            }
        </>
    );
};

export default CarePlan;
